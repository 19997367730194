import ServiceCollection from "src/services/ServiceCollection";
import {
    Button, Container,
    ExpandableSection,
    FormField,
    Grid,
    Header,
    Select,
    SpaceBetween, Tabs,
} from "@amzn/awsui-components-react/polaris";
import TPEAction from "src/models/common/TPEAction";
import React, { useEffect, useState } from "react";
import useReducerWithLogger from "src/services/utils/ReducerWithLogger";
import { reportsReducer } from "src/services/reports/ReportsReducer";
import { initialState, ReportsState } from "src/services/reports/ReportsState";
import {
    GetCLIsByProviderCompanyCodeRequest,
    TPTaxReportMapResponse
} from "src/models/reports/TPTaxReport";
import CONSTANTS from "src/utils/constants";
import {accountingDaysList} from "src/models/reports/GetAccountingDayList"
import { SelectProps, Tiles } from "@amzn/awsui-components-react";
import TPTaxReportTableGrid from "src/components/reports/TPTaxReportTableGrid";
import { FetchReportRequest } from "src/models/reports/FetchReport";
import { parseTpTaxUIFilter } from "./../../utils/ReportUtils"
import {ReportRequestParams } from "src/models/reports/GenerateReport";
import { useHistory, useParams } from "react-router-dom";
import TPEErrorWatcher from "../shared/TPEErrorWatcher";
import {MANAGE_EXCEPTIONS_TAB_URL_PREFIX} from "src/components/ic-account-mapping/constants";

export type ContextType = {
    state: ReportsState,
    dispatch: React.Dispatch<TPEAction>,
    services: ServiceCollection,
}

const TPTaxReportProvider = (props: {
    state: ReportsState, dispatch: React.Dispatch<TPEAction>, services: ServiceCollection,
    children: JSX.Element
}) => {
    const { state, dispatch, services, children } = props;
    const providerValue = React.useMemo(() => ({
        state, dispatch, services
    }), [state, dispatch]);
    return (
        <TPTaxReportContext.Provider value={providerValue}>
            {children}
        </TPTaxReportContext.Provider>
    );
}

export const TPTaxReportContext = React.createContext(null as ContextType | null);
export default function TPTaxReportView(props: { services: ServiceCollection }) {
    const { services } = props;
    const history = useHistory();
    const { filter } = useParams<{ filter: string }>();
    const [currentFilter, setCurrentFilter] = useState(parseTpTaxUIFilter(decodeURIComponent(filter)) || '');
    const [state, dispatch] = useReducerWithLogger(reportsReducer, initialState);
    const [companyCodeOption, setCompanyCodeOption] = React.useState({ value: currentFilter.entity, label: currentFilter.entity } as SelectProps.Option | null);
    const [companyCodeBySegmentList, setCompanyCodeBySegmentList] = React.useState([] as SelectProps.Option[]);
    const [selectedCLIOption, setSelectedCLIOption] = React.useState({ value: currentFilter.cli, label: currentFilter.cli } as SelectProps.Option | null);
    const [selectedAccountingDayOption, setSelectedAccountingDayOption] = React.useState({ value: currentFilter.accountingDay, label: currentFilter.accountingDay } as SelectProps.Option |null);
    const [selectedPeriodNameOption, setSelectedPeriodNameOption] = React.useState({ value: currentFilter.periodName, label: currentFilter.periodName } as SelectProps.Option | null);
    const [cliRequestPayload, setCliRequestPayload] = useState(null as GetCLIsByProviderCompanyCodeRequest | null);
    const [cliList, setCliList] = React.useState([] as SelectProps.Option[]);
    const [dropDownDisabledFlag, setDropDownDisabledFlag] = React.useState(true);
    const [fetchReportRequest, setFetchReportRequest] = React.useState(null as FetchReportRequest | null);
    const [reportData, setReportData] = React.useState(null as TPTaxReportMapResponse | null);
    const [clisResponse, getCLIsLoading, getCLIsError] = services.reportsService.getCLIsByProviderCompanyCode(cliRequestPayload);
    const [previousReportResponse, getPreviousReportLoading, getPreviousReportError] = services.reportsService.fetchReportData(fetchReportRequest);
    const [accountingPeriodResult, accountingPeriodLoading, accountingPeriodError] = services.reportsService.getReportingPeriods();
    const [companyCodesBySegmentListResponse, isCompanyCodesBySegmentDataLoading, companyCodesBySegmentFetchError] = services.reportsService.getCOADataBySegment(CONSTANTS.COA_SEGMENT_MAPPING.COMPANY.WEB_API);
    const [reportCategory, setReportCategory] = React.useState(CONSTANTS.TP_TAX_REPORT_CATEGORY.MARGIN);


    // Handle changes in the filter value
    const handleFilterChange = (newFilter: string) => {
        // Check if the filter string contains "++"
        if (newFilter.includes("+++")) {
            // Handle "++" as a special case
            // Set default or placeholder values for the dropdowns
            setCompanyCodeOption(null);
            setSelectedCLIOption(null);
            setSelectedPeriodNameOption(null);
            setSelectedAccountingDayOption(null);
        } else {
            setCurrentFilter(parseTpTaxUIFilter(newFilter) || '');
            let encodedFilter = encodeURIComponent(newFilter);
            history.push(`/reports/tpTaxReport/${encodedFilter}`);
        }
    };

    useEffect(() => {
        if (companyCodeOption && selectedCLIOption && selectedPeriodNameOption && selectedAccountingDayOption) {
            let companyCode = companyCodeOption?.value || ''
            let cliValue = selectedCLIOption?.value || ''
            let periodName = selectedPeriodNameOption?.value || ''
            let accountingDay = selectedAccountingDayOption?.value || ''
            // Replace for url pattern
            cliValue = cliValue.replace('.', '*')
            const newFilterString = `${companyCode}+${cliValue}+${periodName}+${accountingDay}`;
            handleFilterChange(newFilterString)
        }
    }, [companyCodeOption, selectedCLIOption, selectedPeriodNameOption, selectedAccountingDayOption]);


    useEffect(() => {
        if (!clisResponse) {
            return;
        }
        setCliList([

            ...clisResponse.CLIList.map(value => ({
                label: value,
                value: value
            })),]);

    }, [clisResponse]);

    useEffect(() => {
        if (!companyCodesBySegmentListResponse) {
            return;
        }
        setCompanyCodeBySegmentList([
            ...companyCodesBySegmentListResponse.map(value => ({
                label: `${value.codeId} - ${value.codeName}`  ,
                value: value.codeId
            }))
        ])
    }, [companyCodesBySegmentListResponse]);

    useEffect(() => {
        if (isOptionDefined(companyCodeOption) && isOptionDefined(selectedPeriodNameOption) && isOptionDefined(selectedCLIOption) && isOptionDefined(selectedAccountingDayOption)) {
            setFetchReportRequest({
                reportName: CONSTANTS.REPORT_TYPES.TP_TAX,
                params: fetchReportRequestParams()
            })
        }
    }, [companyCodeOption, selectedPeriodNameOption, selectedCLIOption, selectedAccountingDayOption])


    useEffect(() => {
        if (!previousReportResponse) {
            return;
        }
        if (previousReportResponse.statusMessage === null) {
            setReportData(JSON.parse(previousReportResponse.payload));
        } else {
            setReportData(null)
            services.messageService.showErrorAutoDismissBanner(previousReportResponse.statusMessage)
        }
    }, [previousReportResponse])

    useEffect(() => {
        if (isOptionDefined(companyCodeOption)) {
            getCLIsBasedOnCompanyCodeSelected();
        }
    }, [companyCodeOption]);

    const getCLIsBasedOnCompanyCodeSelected = () => {
        if (isOptionDefined(companyCodeOption)) {
            setCliRequestPayload({
                providerCompanyCode: companyCodeOption?.value as string,
                calculationStatus: CONSTANTS.CALCULATION_STATUS.ACTIVE
            })
            setDropDownDisabledFlag(false);
        }
    }

    const onResetButtonClicked = () => {
        handleFilterChange('')
        setCompanyCodeOption(null)
        setDropDownDisabledFlag(true);
        setCliList([]);
        setReportData(null);
        setSelectedCLIOption(null);
        setSelectedAccountingDayOption(null);
        setSelectedPeriodNameOption(null);
    }
    const resetOnCompanyCodeChange = () => {
        handleFilterChange('')
        setDropDownDisabledFlag(true);
        setCliList([]);
        setReportData(null);
        setSelectedCLIOption(null);
        setSelectedAccountingDayOption(null);
        setSelectedPeriodNameOption(null);
    };

    function isOptionDefined(option?: SelectProps.Option | null) {
        if (option == null) {
            return false
        }
        return option && option.value !== undefined && option.label !== undefined && option.value !== ''
    }
    // Write unit tests 
    function fetchReportRequestParams(): ReportRequestParams {
        return {
            "providerCompanyCode": companyCodeOption?.value as string,
            "tpTaxReportFor": selectedCLIOption?.value as string,
            "period": selectedPeriodNameOption?.value as string,
            "accountingDay": selectedAccountingDayOption?.value as string,
        }
    }


    return (
        <TPTaxReportProvider services={services} state={state} dispatch={dispatch}>
            <div>
                <SpaceBetween size="l" direction="vertical">
                    <Container
                        header={
                            <Header
                                actions={
                                    <SpaceBetween direction="horizontal" size="xs">
                                        <Button variant="link" onClick={onResetButtonClicked}>Reset Filters</Button>
                                    </SpaceBetween>}>
                                Report Filtering Criteria
                            </Header>
                        }
                    >
                        <div>
                            <Grid gridDefinition={[{ colspan: 3 }, { colspan: 3 }, { colspan: 3 }, { colspan: 3 }]}>
                                <SpaceBetween
                                    direction="vertical"
                                    size="xxs">
                                    <FormField label="Company Code">
                                        <Select
                                            statusType={isCompanyCodesBySegmentDataLoading ? "loading" : "finished"}
                                            loadingText="Fetching available company codes"
                                            selectedOption={companyCodeOption}
                                            onChange={({ detail }) => {
                                                resetOnCompanyCodeChange();
                                                setCompanyCodeOption(detail.selectedOption)
                                            }}
                                            options={companyCodeBySegmentList}
                                            selectedAriaLabel="Selected"
                                            placeholder="Select the Company Code for report"
                                            virtualScroll={true}
                                            filteringType="auto"
                                        />
                                    </FormField>
                                </SpaceBetween>
                                
                                <SpaceBetween
                                    direction="vertical"
                                    size="xxs">
                                    <FormField label="CLI">
                                        <Select
                                            statusType={getCLIsLoading ? "loading" : "finished"}
                                            disabled={dropDownDisabledFlag}
                                            loadingText="Fetching available options"
                                            selectedOption={selectedCLIOption}
                                            onChange={({ detail }) => {
                                                setSelectedCLIOption(detail.selectedOption)
                                            }}
                                            options={cliList}
                                            selectedAriaLabel="Selected"
                                            placeholder="Select the CLI for report"
                                            virtualScroll={true}
                                            filteringType="auto"
                                        />
                                    </FormField>
                                </SpaceBetween>
                                <SpaceBetween
                                    direction="vertical"
                                    size="xxs">
                                    <FormField label="Accounting Period">
                                        <Select
                                            statusType={accountingPeriodLoading ? "loading" : "finished"}
                                            disabled={dropDownDisabledFlag}
                                            loadingText="Fetching available options"
                                            selectedOption={selectedPeriodNameOption}
                                            onChange={({ detail }) =>
                                                setSelectedPeriodNameOption(detail.selectedOption)
                                            }
                                            options={
                                                accountingPeriodResult == null
                                                    ? []
                                                    : accountingPeriodResult.ReportingPeriodList.map(
                                                        (accountingPeriodNameValue) => ({
                                                            label: accountingPeriodNameValue,
                                                            value: accountingPeriodNameValue,
                                                        })
                                                    )
                                            }
                                            placeholder="Select the accounting period"
                                            virtualScroll={true}
                                            filteringType="auto"
                                        />
                                    </FormField>
                                </SpaceBetween>
                                <SpaceBetween
                                    direction="vertical"
                                    size="xxs">
                                    <FormField label="Accounting Day">
                                        <Select
                                            disabled={dropDownDisabledFlag}
                                            selectedOption={selectedAccountingDayOption}
                                            options={accountingDaysList}
                                            onChange={({detail}) => {
                                                setSelectedAccountingDayOption(detail.selectedOption);
                                            }}
                                            selectedAriaLabel="Selected"
                                            placeholder="Select Accounting Day"
                                        />
                                    </FormField>
                                </SpaceBetween>
                            </Grid>
                        </div>
                    </Container>
                    {reportData && <>
                        <Tiles
                            onChange={({ detail }) => setReportCategory(detail.value)}
                            value={reportCategory}
                            items={[
                                {
                                    label: CONSTANTS.TP_TAX_REPORT_CATEGORY.MARGIN,
                                    value: CONSTANTS.TP_TAX_REPORT_CATEGORY.MARGIN
                                },
                                {
                                    label: CONSTANTS.TP_TAX_REPORT_CATEGORY.ALLOCATION,
                                    value: CONSTANTS.TP_TAX_REPORT_CATEGORY.ALLOCATION
                                },
                            ]}
                        />

                        <Container
                            header={
                                <Header>
                                    {reportCategory + " Report"}
                                </Header>
                            }
                        >
                            {reportCategory == CONSTANTS.TP_TAX_REPORT_CATEGORY.MARGIN &&
                                <>
                                    <ExpandableSection variant={'container'} headerText="Details of CLI">
                                        <TPTaxReportTableGrid data={reportData?.reportContent?.DETAIL} services={services} columnHeaders={reportData?.reportHeaders} loadingStatus={getPreviousReportLoading}/>
                                    </ExpandableSection>
                                    <ExpandableSection variant={'container'} headerText="TP Profit & Loss (P&L)"
                                                       headerActions={<Button
                                                           onClick={() => history.push(`${MANAGE_EXCEPTIONS_TAB_URL_PREFIX}?cli=${selectedCLIOption?.value as string}`)}>Manage
                                                           P&L Mapping</Button>}>
                                        <TPTaxReportTableGrid data={reportData?.reportContent?.TP_PNL} services={services} columnHeaders={reportData?.reportHeaders} loadingStatus={getPreviousReportLoading}/>
                                    </ExpandableSection>
                                    <ExpandableSection variant={'container'} headerText="Profit Level Indicator (PLI)">
                                        <Tabs
                                            tabs={[
                                                {
                                                    label: "Return on Cost",
                                                    id: "ncp",
                                                    content: <TPTaxReportTableGrid data={reportData?.reportContent?.NCP_PLI} services={services} columnHeaders={reportData?.reportHeaders} loadingStatus={getPreviousReportLoading}/>
                                                },
                                                {
                                                    label: "Return on Sales",
                                                    id: "ros",
                                                    content: <TPTaxReportTableGrid data={reportData?.reportContent?.ROS_PLI} services={services} columnHeaders={reportData?.reportHeaders} loadingStatus={getPreviousReportLoading}/>
                                                },
                                                {
                                                    label: "Berry Ratio",
                                                    id: "br",
                                                    content: <TPTaxReportTableGrid data={reportData?.reportContent?.BR_PLI} services={services} columnHeaders={reportData?.reportHeaders} loadingStatus={getPreviousReportLoading}/>
                                                }
                                            ]}
                                        />

                                    </ExpandableSection>
                                </>
                            }
                            {reportCategory == CONSTANTS.TP_TAX_REPORT_CATEGORY.ALLOCATION &&
                                <>
                                    <ExpandableSection variant={'container'} headerText="Details of CLI">
                                        <TPTaxReportTableGrid data={reportData?.reportContent?.DETAIL} services={services} columnHeaders={reportData?.reportHeaders} loadingStatus={getPreviousReportLoading}/>
                                    </ExpandableSection>
                                    <ExpandableSection variant={'container'} headerText="Allocation">
                                        <TPTaxReportTableGrid data={reportData?.reportContent?.ALLOCATION} services={services} columnHeaders={reportData?.reportHeaders} loadingStatus={getPreviousReportLoading}/>
                                    </ExpandableSection>
                                </>
                            }
                        </Container>
                    </>}
                </SpaceBetween>
                <TPEErrorWatcher services={services} errors={[getCLIsError, companyCodesBySegmentFetchError, getPreviousReportError, accountingPeriodError]} />
            </div>
        </TPTaxReportProvider>
    )
}