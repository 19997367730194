import React, {useEffect, useState} from "react";
import {
    CalendarProps,
    DateInputProps,
    DatePicker,
    FormField,
    TimeInput,
    TimeInputProps
} from "@amzn/awsui-components-react/polaris";
import {Calendar, DateInput} from "@amzn/awsui-components-react";
import {PropertyFilterOperatorFormProps} from "@amzn/awsui-collection-hooks";

function isValidIsoDate(isoDate: string) {
    return !isNaN(new Date(isoDate).getTime());
}

/**
 * This function parses the filter text and returns the date and time values.
 *
 * Examples:
 *
 * Input: "2023"
 * Output: { dateValue: "2023-01-01", timeValue: "" }
 *
 * Input: "2023-12"
 * Output: { dateValue: "2023-12-01", timeValue: "" }
 *
 * Input: "2023-12-25"
 * Output: { dateValue: "2023-12-25", timeValue: "" }
 *
 * Input: "2023/12/25"
 * Output: { dateValue: "2023-12-25", timeValue: "" }
 *
 * Input: "2023-12-25T14"
 * Output: { dateValue: "2023-12-25", timeValue: "14:00:00" }
 *
 * Input: "2023-12-25T14:30"
 * Output: { dateValue: "2023-12-25", timeValue: "14:30:00" }
 *
 * Input: "2023-12-25T14:30:45"
 * Output: { dateValue: "2023-12-25", timeValue: "14:30:45" }
 */
function parseDateTimeFilter(filter: string) {
    const regexDate = /^(\d\d\d\d(-|\/\d\d)?(-|\/\d\d)?)(T\d\d(:\d\d)?(:\d\d)?)?/;
    const dateTime = filter.match(regexDate)?.[0] || '';

    let [dateValue, timeValue = ''] = dateTime.split('T');
    const [year, month = '01', day = '01'] = dateValue.split(/-|\//);
    const [hours = '00', minutes = '00', seconds = '00'] = timeValue.split(':');
    dateValue = year.length === 4 ? `${year}-${month}-${day}` : '';
    timeValue = timeValue ? `${hours}:${minutes}:${seconds}` : '';

    const value = !timeValue ? dateValue : dateValue + 'T' + timeValue;
    return isValidIsoDate(value) ? {dateValue, timeValue} : {dateValue: '', timeValue: ''};
}

function parseValue(value: string) {
    const [datePart = '', timePart = ''] = (value).split('T');
    return {dateValue: datePart, timeValue: timePart};
}

export function DateTimeForm({filter, value, onChange}: PropertyFilterOperatorFormProps<string>) {
    const [{dateValue, timeValue}, setState] = useState(parseValue(value ?? ''));

    const onChangeDate = (dateValue: string) => {
        setState(state => ({...state, dateValue}));
    };

    const onChangeTime = (timeValue: string) => {
        setState(state => ({...state, timeValue}));
    };

    // Parse value from filter text when it changes.
    useEffect(
        () => {
            if (filter) {
                setState(parseDateTimeFilter(filter.trim()));
            }
        },
        [filter],
    );

    // Call onChange only when the value is valid.
    useEffect(
        () => {
            const dateAndTimeValue = dateValue + 'T' + (timeValue || '00:00:00');

            if (!dateValue.trim()) {
                onChange(null);
            } else if (isValidIsoDate(dateAndTimeValue)) {
                onChange(dateAndTimeValue);
            }
        },
        [dateValue, timeValue],
    );

    const dateInputProps: DateInputProps = {
        placeholder: 'YYYY/MM/DD',
        value: dateValue,
        onChange: event => onChangeDate(event.detail.value),
    };
    const timeInputProps: TimeInputProps = {
        format: 'hh:mm:ss',
        placeholder: 'hh:mm:ss',
        value: timeValue,
        onChange: event => onChangeTime(event.detail.value),
    };
    const calendarProps: CalendarProps = {
        value: dateValue,
        locale: 'en-EN',
        onChange: event => onChangeDate(event.detail.value),
    };

    if (typeof filter !== 'undefined') {
        return (
            <div className="date-time-form">
                <FormField description="Date">
                    <DateInput {...dateInputProps} />
                </FormField>

                <FormField description="Time">
                    <TimeInput {...timeInputProps} />
                </FormField>

                <Calendar {...calendarProps} />
            </div>
        );
    }

    return (
        <div className="date-time-form">
            <FormField description="Date">
                <DatePicker {...dateInputProps} {...calendarProps} />
            </FormField>

            <FormField description="Time">
                <TimeInput {...timeInputProps} />
            </FormField>
        </div>
    );
}