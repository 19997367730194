import { OpenApprovalsSearchRequest } from "./OpenApproval";
import { OpenCalcReviewsSearchRequest } from "./OpenCalculationReview";
import { OpenRequestsSearchRequest } from "./OpenRequest";

export interface DashboardState {
    isLoading: Boolean,
    error: string,
    searchOpenCalcReviewsPayload?: OpenCalcReviewsSearchRequest,
    searchOpenApprovalsPayload?: OpenApprovalsSearchRequest,
    searchOpenRequestsPayload?: OpenRequestsSearchRequest
}

export const initialState: DashboardState = {
    isLoading: true,
    error: '',
};