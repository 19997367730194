import React, {useEffect, useRef, useState} from "react";
import {Box, SelectProps} from "@amzn/awsui-components-react";
import {Grid, Select} from "@amzn/awsui-components-react/polaris";
import ATPHorizontalRadioGroup from "src/components/shared/ATPHorizontalRadioGroup";
import ServiceCollection from "src/services/ServiceCollection";
import ManageExceptionsCLIView from "src/components/ic-account-mapping/ManageExceptionsCLIView";
import CONSTANTS from "src/utils/constants";
import {useHistory, useLocation} from "react-router-dom";
import {createWorkbookLabel, extractWorkbookIdFromWorkbookLabel} from "src/utils/ReportUtils";
import {MANAGE_EXCEPTIONS_TAB_URL_PREFIX} from "src/components/ic-account-mapping/constants";
import Spinner from "@amzn/awsui-components-react/polaris/spinner";
import ManageExceptionsWorkbookView from "src/components/ic-account-mapping/ManageExceptionsWorkbookView";

enum ManageExceptionsViewType {
    WORKBOOK_VIEW = "workbookView",
    CLI_VIEW = "cliView"
}

export default function ManageExceptionsSectionView(props: { services: ServiceCollection }) {
    const history = useHistory();
    const location = useLocation();
    const [currentUrlParams, setCurrentUrlParams] = useState(new URLSearchParams(location.search));
    const [viewType, setViewType] = React.useState<ManageExceptionsViewType>(ManageExceptionsViewType.WORKBOOK_VIEW);
    const [
        selectedOption,
        setSelectedOption
    ] = React.useState<SelectProps.Option>(null as unknown as SelectProps.Option);
    const loadTime = useRef((new Date()).toString());
    const [calculationLineNumber, loadingCalcLineNumber, errorCalcLineNumber] = props.services.cliService.getCLIs(loadTime.current, undefined, undefined, CONSTANTS.ACTIVE_STATUS);
    const [workbookListResult, workbookListLoading, workbookListResultError] = props.services.mecService.getWorkbooks(loadTime.current);

    useEffect(() => {
        if (workbookListResultError) {
            props.services.messageService.showErrorAutoDismissBanner(workbookListResultError || errorCalcLineNumber);
        }
    }, [errorCalcLineNumber, workbookListResultError]);

    useEffect(() => {
        setCurrentUrlParams(new URLSearchParams(location.search));
    }, [location.search]);

    useEffect(() => {
        if (currentUrlParams) {
            const workbookLabel = decodeURIComponent(currentUrlParams.get('workbookLabel') ?? '');
            const cli = decodeURIComponent(currentUrlParams.get('cli') ?? '');
            if (workbookLabel) {
                setSelectedOption({value: extractWorkbookIdFromWorkbookLabel(workbookLabel), label: workbookLabel});
                setViewType(ManageExceptionsViewType.WORKBOOK_VIEW);
            } else if (cli) {
                setSelectedOption({value: cli, label: cli});
                setViewType(ManageExceptionsViewType.CLI_VIEW);
            }
        }
    }, [currentUrlParams]);

    return (
        <>
            <Grid gridDefinition={[
                {colspan: 4},
                {colspan: 3},
            ]}>
                <Select
                    selectedOption={selectedOption}
                    onChange={({detail}) => {
                        if (viewType === ManageExceptionsViewType.WORKBOOK_VIEW) {
                            history.push(`${MANAGE_EXCEPTIONS_TAB_URL_PREFIX}?workbookLabel=${encodeURIComponent(detail.selectedOption.label as string)}`)
                        } else {
                            history.push(`${MANAGE_EXCEPTIONS_TAB_URL_PREFIX}?cli=${detail.selectedOption.value}`)
                        }
                    }}
                    inlineLabelText={"View CWB or CLI"}
                    options={viewType === ManageExceptionsViewType.WORKBOOK_VIEW ? workbookListResult.map((workbook) => {
                            return {
                                value: workbook.workbookId,
                                label: createWorkbookLabel(workbook.workbookId as string, workbook.workbookName)
                            }
                        }) :
                        calculationLineNumber.map((cli) => {
                            return {
                                value: cli,
                                label: cli
                            }
                        })}
                    filteringType="auto"
                    placeholder={"Select an option"}
                    loadingText={"Loading options"}
                    statusType={(workbookListLoading || loadingCalcLineNumber) ? 'loading' : 'finished'}
                />
                <Box padding={{top: "m"}}>
                    <ATPHorizontalRadioGroup
                        items={[{
                            value: ManageExceptionsViewType.WORKBOOK_VIEW,
                            label: "Workbook View"
                        }, {value: ManageExceptionsViewType.CLI_VIEW, label: "CLI View"}]}
                        value={viewType}
                        onChange={() => {
                            setSelectedOption(null as unknown as SelectProps.Option)
                            setViewType(viewType === ManageExceptionsViewType.WORKBOOK_VIEW ? ManageExceptionsViewType.CLI_VIEW : ManageExceptionsViewType.WORKBOOK_VIEW)
                        }}
                    />
                </Box>
            </Grid>

            <br/>

            {((workbookListLoading || loadingCalcLineNumber) && selectedOption) ? (
                <Box textAlign="center">
                    <Spinner size="large"/>
                </Box>
            ) : (!workbookListLoading && !loadingCalcLineNumber && selectedOption) && (
                viewType === ManageExceptionsViewType.WORKBOOK_VIEW ? (
                    <ManageExceptionsWorkbookView services={props.services}
                                                  selectedWorkbookId={selectedOption.value as string}
                                                  selectedWorkbookLabel={selectedOption.label as string}/>
                ) : (
                    <ManageExceptionsCLIView
                        services={props.services}
                        selectedCli={selectedOption.value as string}
                    />
                )
            )}
        </>
    );
}