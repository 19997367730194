import React, { useEffect, useMemo, useState } from 'react';
import { Box, Button, ButtonDropdown, Icon, Link, Popover, SpaceBetween, StatusIndicator, StatusIndicatorProps } from '@amzn/awsui-components-react';
import { CalculationBuilderContext } from './CalculationBuilderView';
import { ACTIONS } from 'src/services/calculation-builder/CalculationBuilderReducer';
import CONSTANTS from 'src/utils/constants';
import StringUtils from 'src/utils/stringUtils';
import { ACTION_TYPE, TPEBasicModal } from '../shared/TPEBasicModal';
import TPEErrorWatcher from '../shared/TPEErrorWatcher';
import EntityStatusUtil from 'src/services/common/EntityStatusUtil';
import * as Moment from 'moment';
import { extendMoment } from 'moment-range';
import { CalculationTemplateMetaData } from 'src/models/calculation-builder/CalculationTemplate';
import { CLI } from 'src/models/common/CLI';
import { CalculationSourceType } from 'src/models/common/CalculationSource';

function CalculationStatusLabel(props: {
    calculation?: CLI, 
    calculationSource: CalculationSourceType, 
    calculationMetadata?: CalculationTemplateMetaData}) {
    const {calculation, calculationSource, calculationMetadata } = props;
    if (calculation == null || calculationMetadata == null){
        return <strong>ATP Calculation Status:</strong>;
    }
    return calculationSource == CalculationSourceType.APTTUS && calculationMetadata?.calculationStatus != calculation?.calculationStatus?
        <Popover
            dismissButton={false}
            position="top"
            size="small"
            content={<span className="statusInfoItem">The ATP calculation status in Apttus is ‘{calculation?.calculationStatus}’. Please update it to ‘{calculationMetadata?.calculationStatus}’ in Apttus.</span>}
            >
            <strong className='warningText'>ATP Calculation Status<Icon data-class='inlineIcon' variant='warning' name="status-warning"/>&nbsp;:</strong>
        </Popover> : 
        <strong>ATP Calculation Status:</strong>;
}

export function CalculationStatusBar() {
    const { services, calcBuilderState, calcBuilderDispatch } = React.useContext(CalculationBuilderContext);

    const { calculation,
            executionPeriod,
            executionPeriodWasTouched,
            isEditingTemplate,
            calculationVersions,
            calculationTemplateMetaData,
            calculationSource } = calcBuilderState;

    const getExecutionPeriods = () => {
        const executionPeriods: any[] = [];
        executionPeriods.push({ id: CONSTANTS.CLEAR_SELECTION, text: CONSTANTS.CLEAR_SELECTION });
        const moment = extendMoment(Moment);
        const endDate = moment().subtract(1, 'months').date(1);
        Array.from(moment.rangeFromInterval('month', -11, endDate).by('month')).reverse().forEach(d => {
            const formattedDate = d.format('MMM-YYYY').toUpperCase();
            executionPeriods.push({ id: formattedDate, text: formattedDate });
        });
        return executionPeriods;
    }

    const executionPeriods = useMemo(getExecutionPeriods, []);
    const [selectedExecutionPeriod, setSelectedExecutionPeriod] = useState(null as unknown as string);
    const [loadTime, setLoadTime] = useState('');
    const [showDiscardDraftConfirmationModal, setShowDiscardDraftConfirmationModal] = useState(false);

    const [calculationExecutionResult, calculationExecutionLoading, calculationExecutionError] = services.calculationBuilderService.getCalculationExecution(calculationTemplateMetaData?.atpCalculationId as string, selectedExecutionPeriod);
    const [discardDraftResult, discardingDraft, discardDraftError] = services.calculationBuilderService.discardDraft(loadTime, calculation?.calculationNumber as unknown as string);
    
    useEffect(() => {
        if (calculationExecutionResult == null) {
            return;
        }
        if (StringUtils.isNullOrEmpty(calculationExecutionResult.calculationExecutionId)) {
            services.messageService.showErrorBanner(`Cannot find calculation execution for ${selectedExecutionPeriod}.`);
        } else {
            calcBuilderDispatch(ACTIONS.SET_EXECUTION_PERIOD.withPayload(selectedExecutionPeriod))
            calcBuilderDispatch(ACTIONS.SET_CALCULATION_EXECUTION.withPayload(calculationExecutionResult));
        }
    }, [calculationExecutionResult])

    useEffect(() => {
        // Resetting this only after user has touched the execution period dropdown
        if (executionPeriodWasTouched && selectedExecutionPeriod == null) {
            // Reset view
            calcBuilderDispatch(ACTIONS.SET_EXECUTION_PERIOD.withPayload(null));
            calcBuilderDispatch(ACTIONS.SET_CALCULATION_EXECUTION.withPayload(null));
        }
    }, [selectedExecutionPeriod])

    useEffect(() => {
        if (discardDraftResult == null) {
            return;
        }
        services.messageService.showSuccessAutoDismissBanner('Calculation draft was discarded successfully.', 10000);
        let refreshJournals = false;
        if (calculationVersions != null && calculationVersions.includes(CONSTANTS.CALCULATION_VERSIONS.CURRENT_VERSION)) {
            calcBuilderDispatch(ACTIONS.SET_SELECTED_CALCULATION_VERSION.withPayload(CONSTANTS.CALCULATION_VERSIONS.CURRENT_VERSION));
            refreshJournals = true;
        }
        calcBuilderDispatch(ACTIONS.REFRESH_ALL_CALCULATION_INFORMATION);
        calcBuilderDispatch(ACTIONS.SET_REFRESH_DATASOURCES_FLAG.withPayload(true));
        calcBuilderDispatch(ACTIONS.SET_REFRESH_CALC_STEPS_FLAG.withPayload(true));
        calcBuilderDispatch(ACTIONS.SET_REFRESH_JOURNALS_FLAG.withPayload(true));
        calcBuilderDispatch(ACTIONS.SET_REFRESH_REVIEW_JOURNALS_FLAG.withPayload(true));
    }, [discardDraftResult])

    const alertText = EntityStatusUtil.getAlertText('calculation', calculationExecutionResult?.dryRun, executionPeriod, calculationTemplateMetaData?.calculationStatus);

    return <React.Fragment>
            <Box padding={{ left: "n", right: "l", top:"xl", bottom:"s" }}>
                <div className="entityStatusBar">
                    <div className="topMarginDiv entityStatusBarElement">
                        <SpaceBetween size="s" direction="horizontal">
                            <CalculationStatusLabel calculation={calculation} calculationSource={calculationSource} calculationMetadata={calculationTemplateMetaData} />
                            <div>
                                <StatusIndicator data-class="statusText" type={EntityStatusUtil.mapToStatusIndicatorBy(calculationTemplateMetaData?.calculationStatus)}>{calculationTemplateMetaData?.calculationStatus}</StatusIndicator>
                                {(!StringUtils.isNullOrEmpty(calculationTemplateMetaData?.statusReason) 
                                    || !StringUtils.isNullOrEmpty(calculationTemplateMetaData?.submitUser)
                                    || calculationTemplateMetaData?.calculationStatus == CONSTANTS.CALCULATION_STATUS.DRAFT_IN_PROGRESS) &&
                                    <sub>
                                    <Popover
                                        data-class="basicPopover"
                                        dismissButton={false}
                                        position="top"
                                        triggerType="custom"
                                        content={renderInfoContent(calculationTemplateMetaData)}
                                        >
                                            <Link data-class="statusReasonInfoLink" variant="info">Info</Link>
                                    </Popover>
                                    </sub>
                                }
                            </div>
                            {calculationTemplateMetaData?.calculationStatus == CONSTANTS.CALCULATION_STATUS.DRAFT_IN_PROGRESS && 
                                <Button data-class="iconButton" 
                                    onClick={() => setShowDiscardDraftConfirmationModal(true)}
                                    iconName="remove" variant="inline-icon" 
                                    disabled={isEditingTemplate || !StringUtils.isNullOrEmpty(calculationTemplateMetaData?.statusReason)}/>
                            }
                        </SpaceBetween>
                    </div>
                    {
                        CalculationSourceType.APTTUS && <div className="topMarginDiv verticalSeparator entityStatusBarElement">
                            <SpaceBetween size="xs" direction="horizontal"><strong>APTTUS CLI Status:</strong>{calculation?.apttusCalculationStatus}</SpaceBetween>
                        </div>
                    }
                    <div className="verticalSeparator entityStatusBarElement">
                        <Box float="right">
                            <SpaceBetween size="s" direction="horizontal">
                                <ButtonDropdown
                                    className="polarisSmallButtonDropdown"
                                    disabled={calculationExecutionLoading}
                                    items={executionPeriods}
                                    onItemClick={({ detail }) => {
                                        setSelectedExecutionPeriod(detail.id == CONSTANTS.CLEAR_SELECTION ? null as unknown as string : detail.id);
                                    }}
                                >
                                    {calculationExecutionLoading ? `Checking ${selectedExecutionPeriod} executions...` : 
                                        (selectedExecutionPeriod == null ? 'View past MEC execution results' : selectedExecutionPeriod)}
                                </ButtonDropdown>
                            </SpaceBetween>
                        </Box>
                    </div>
                    <TPEBasicModal
                        title="Discard draft confirmation"
                        action={ACTION_TYPE.CONFIRM_CANCEL}
                        visible={showDiscardDraftConfirmationModal}
                        onConfirm={() => { setLoadTime(new Date().toDateString()); setShowDiscardDraftConfirmationModal(false); }}
                        onCancel={() => setShowDiscardDraftConfirmationModal(false)}
                    >
                        {calculationVersions != null && calculationVersions.includes(CONSTANTS.CALCULATION_VERSIONS.CURRENT_VERSION) ?
                            'This will discard all changes made to the calculation. '
                        :
                            'This will clear the calculation. '
                        }
                        {calculationTemplateMetaData?.templateId != null && 'Note that any changes saved to the calculation template will not be reverted. '}
                        Confirm to proceed.
                    </TPEBasicModal>
                    <TPEErrorWatcher services={services} errors={[calculationExecutionError, discardDraftError]} />
                </div>
            </Box>
            {!StringUtils.isNullOrEmpty(alertText) && 
                <div className="subtleAlert"><StatusIndicator data-class="alertText" type="info">{alertText}</StatusIndicator></div>
            }
        </React.Fragment>
}

function renderInfoContent(calculationTemplateMetaData?: CalculationTemplateMetaData) {
    if (calculationTemplateMetaData == undefined) {
        return '';
    }

    return <React.Fragment>
        {!StringUtils.isNullOrEmpty(calculationTemplateMetaData.submitUser) &&
            <ul>
                <li className="statusInfoItem"><b>Submitted by: </b>{calculationTemplateMetaData.submitUser}</li>
                <li className="statusInfoItem"><b>Submitted on: </b>{calculationTemplateMetaData.submitDateDisplay}</li>
                <li className="statusInfoItem"><b>Submit comment: </b>{calculationTemplateMetaData.submitComment}</li>
                {!StringUtils.isNullOrEmpty(calculationTemplateMetaData.reviewUser) && 
                    <React.Fragment>
                        <li className="statusInfoItem"><b>Reviewed by: </b>{calculationTemplateMetaData.reviewUser}</li>
                        <li className="statusInfoItem"><b>Reviewed on: </b>{calculationTemplateMetaData.reviewDateDisplay}</li>
                        {!StringUtils.isNullOrEmpty(calculationTemplateMetaData.reviewComment) &&
                            <li className="statusInfoItem"><b>Review comment: </b>{calculationTemplateMetaData.reviewComment}</li>
                        }
                    </React.Fragment>
                }
            </ul>
        }
        {calculationTemplateMetaData?.calculationStatus == CONSTANTS.CALCULATION_STATUS.DRAFT_IN_PROGRESS &&
            <ul>
                <li className="statusInfoItem"><b>Draft created by: </b>{calculationTemplateMetaData.lastModifiedUser}</li>
                <li className="statusInfoItem"><b>Draft created on: </b>{calculationTemplateMetaData.lastModifiedDate}</li>
            </ul>
        }
        {!StringUtils.isNullOrEmpty(calculationTemplateMetaData.statusReason) &&
            <React.Fragment>
                {(!StringUtils.isNullOrEmpty(calculationTemplateMetaData.submitUser) || calculationTemplateMetaData?.calculationStatus == CONSTANTS.CALCULATION_STATUS.DRAFT_IN_PROGRESS) && <hr/>}
                <span className="statusInfoItem">
                    <span>{calculationTemplateMetaData.statusReason}</span>
                    { calculationTemplateMetaData?.calculationStatus == CONSTANTS.CALCULATION_STATUS.DRAFT_IN_PROGRESS && <span> This draft cannot be discarded.</span> }
                </span>
            </React.Fragment>
        }
    </React.Fragment>
}
