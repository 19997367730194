import apiService from "src/services/ApiCallService";
import {useEffect, useState} from "react";
import ErrorUtils from "src/utils/ErrorUtils";
import StringUtils from "src/utils/stringUtils";
import YtdPeriodBalance from "src/models/periodCharges/YtdPeriodBalance";
import PeriodChargesLOVsResponse from "src/models/periodCharges/PeriodChargesLOVsResponse";
import DateUtils from "src/utils/dateUtils";
import CONSTANTS from "src/utils/constants";
import ArrayUtils from "src/utils/arrayUtils";


export default class PeriodChargesService {

    downloadTemplate(query: string | null): [string, boolean, string] {
        const [result, setResult] = useState(null as unknown as string);
        const [loading, setLoading] = useState(false);
        const [error, setError] = useState('');

        useEffect(() => {
            async function downloadTemplate() {
                try {
                    setLoading(true);
                    const response = await apiService.downloadFile(CONSTANTS.ENVIRONMENT_VARIABLES.TEMPLATES_BUCKET, CONSTANTS.ENVIRONMENT_VARIABLES.PERIOD_CHARGES_UPLOAD_TEMPLATE);
                    const json = await response.json();
                    setResult(json.downloadUrl);
                } catch (ex) {
                    setError(ErrorUtils.getMessage(ex));
                } finally {
                    setLoading(false);
                }
            }
            if (!StringUtils.isNullOrEmpty(query)) {
                downloadTemplate();
            }
        }, [query]);
        return [result, loading, error]
    }

    uploadFile(key: string | undefined, file: File): [string | null, boolean, string] {
        const [result, setResult] = useState(null as string | null);
        const [loading, setLoading] = useState(false);
        const [error, setError] = useState('');

        useEffect(() => {
            async function uploadData(key: string) {
                try {
                    setLoading(true);
                    await apiService.uploadToS3(CONSTANTS.ENVIRONMENT_VARIABLES.PERIOD_CHARGES_UPLOAD_BUCKET, key || '', file);
                    const response = await apiService.uploadPeriodCharges(key);
                    setResult((await response.json()).s3Key as string);
                } catch (ex) {
                    setError(ErrorUtils.getMessage(ex));
                } finally {
                    setLoading(false);
                }
            }
            if (!StringUtils.isNullOrEmpty(key)) {
                uploadData(key || '');
            }
        }, [key]);

        return [result, loading, error];
    }
    

    getPeriodCharges(fetchFlag: string, payload: string) : [YtdPeriodBalance[], boolean, string] {
        const [results, setResults] = useState([] as YtdPeriodBalance[]);
        const [loading, setLoading] = useState(false);
        const [error, setError] = useState('')

        useEffect(() => {
            async function fetchData() {
                try {
                    setLoading(true);
                    setError('');
                    const response = await apiService.getPeriodCharges(payload);
                    const json = (await response.json()).periodChargeList as YtdPeriodBalance[];
                    setResults(formatYtdBalances(json));
                    setError('');
                }
                catch (ex) {
                    setResults([]);
                    setError(ErrorUtils.getMessage(ex));
                }
                finally {
                    setLoading(false);
                }
            }
            if (!StringUtils.isNullOrEmpty(fetchFlag) && !StringUtils.isNullOrEmpty(payload)) {
                fetchData();
            }
        }, [fetchFlag, payload]);
        return [results, loading, error];
    }

    getLOVs(): [PeriodChargesLOVsResponse | null, boolean, string]{
        const [results, setResults] = useState(null as PeriodChargesLOVsResponse | null);
        const [loading, setLoading] = useState(false);
        const [error, setError] = useState('')

        useEffect(() => {
            async function fetchData() {
                try {
                    setLoading(true);
                    //const response = await apiService.getYtdBalances(payload);
                    //const json = await response.json() as YtdPeriodBalance[];
                    setResults({
                        years: ["2025","2024","2023"],
                        assignees: ["payara", "anirvis", "chashank"],
                        statuses: ["Pending approval", "Approved", "No approval needed","Overdue"],
                        clis: []
                    });
                }
                catch (ex) {
                    setError(ErrorUtils.getMessage(ex));
                }
                finally {
                    setLoading(false);
                }
            }
            fetchData();
        }, []);

        return [results, loading, error];
    }

    saveAdjustment(payload: any): [any, any, any] {
        const [results, setResults] = useState(null as any);
        const [loading, setLoading] = useState(false);
        const [error, setError] = useState('')

        useEffect(() => {
            async function fetchData() {
                try {
                    setLoading(true);
                    setError('');
                    const response = await apiService.savePeriodChargeAdjustment(payload.calculationNumber, 
                        {   
                            calculationNumber: payload.calculationNumber,
                            adjustmentBaseAmount: payload.adjustmentBaseAmount, 
                            adjustmentTotalAmount: payload.adjustmentTotalAmount
                        });
                    const json = await response.json();
                    setResults(json);
                }
                catch (ex) {
                    setError(ErrorUtils.getMessage(ex));
                }
                finally {
                    setLoading(false);
                }
            }
            if (payload != null) {
                fetchData();
            }
        }, [payload]);
        return [results, loading, error];
    }
}

function formatYtdBalances(balances: YtdPeriodBalance[]): YtdPeriodBalance[] {
    if (ArrayUtils.isNullOrEmpty(balances)){
        return [];
    }
    if (balances.length > 1){
        // Items from API come ordered by lastUpdatedDate ascending. 
        // So the previous to last item is the most recent one.
        const lastAdjustment = balances[balances.length - 2];
        balances[balances.length -1].lastUpdatedDate = lastAdjustment.lastUpdatedDate;
    }
    return balances.map(x => {
            const trimmedPeriodId = x.periodId.substring(0,6);
            return {
            ...x,
            updatedOn: DateUtils.formatTimestamp(x.lastUpdatedDate),
            createDateFormatted: DateUtils.formatTimestamp(x.createDate?.toString()),
            month: DateUtils.formatDate(trimmedPeriodId,"MMM"),
            year: DateUtils.formatDate(trimmedPeriodId, "YYYY")
        }
    });
}
