import React, {useEffect} from "react";
import ServiceCollection from "src/services/ServiceCollection";
import {
    NO_DATA_MESSAGE, NO_MATCH_MESSAGE
} from "src/components/ic-account-mapping/constants";
import {
    PropertyFilterOperator,
    PropertyFilterOperatorExtended,
    PropertyFilterProperty,
    useCollection
} from "@amzn/awsui-collection-hooks";
import EmptyTableView from "src/components/ic-account-mapping/shared/EmptyTableView";
import {formatDateTime} from "src/components/ic-account-mapping/Utils";
import {Box, CollectionPreferencesProps, Select, StatusIndicator} from "@amzn/awsui-components-react";
import {DateTimeForm} from "src/components/ic-account-mapping/shared/DateTimeForm";
import OverrideWarningModal from "src/components/ic-account-mapping/shared/OverrideWarningModal";
import BaseTableView from "src/components/ic-account-mapping/shared/BaseTableView";
import {TableProps} from "@amzn/awsui-components-react/polaris/table";
import {
    AccountMappingRecord,
    AccountMappingStatus,
    AccountMappingType,
    AccountType
} from "src/models/reports/GetAccountMapping";
import Spinner from "@amzn/awsui-components-react/polaris/spinner";

const ColumnIdToColumnNameMapping = {
    accountId: "Account ID",
    accountName: "Account Name",
    parentAccountId: "Parent ID",
    parentAccountName: "Parent Name",
    mappingStatus: "Status",
    defaultMapping: "Default Mapping",
    exceptionMapping: "Exception Mapping",
    updatedBy: "Exception Updated By",
    updatedAt: "Exception Updated On"
}

function getEditConfig(columnId: string): TableProps.EditConfig<any> | undefined {
    switch (columnId) {
        case ColumnIdToColumnNameMapping.exceptionMapping:
            return {
                editingCell: (rowItem, {currentValue, setValue}) => {
                    const value = currentValue ?? rowItem.exceptionMapping;
                    return (
                        <Select
                            autoFocus={true}
                            expandToViewport={true}
                            selectedOption={
                                Object.values(AccountType).map(value => ({
                                    label: value,
                                    value: value
                                })).find(
                                    option => option.value === value
                                ) ?? null
                            }
                            onChange={event => {
                                setValue(
                                    event.detail.selectedOption.value ??
                                    rowItem.exceptionMapping
                                );
                            }}
                            options={Object.values(AccountType).map(value => ({
                                label: value,
                                value: value
                            }))}
                        />
                    );
                }
            };
        default:
            return undefined;
    }
}

function prepareColumnDefinition(): TableProps.ColumnDefinition<any>[] {
    const columnDefinitions: TableProps.ColumnDefinition<any>[] = [];
    Object.entries(ColumnIdToColumnNameMapping).forEach(([columnId, columnName]: [string, string]) => {
        columnDefinitions.push({
            id: columnId,
            header: columnName,
            cell: values => {
                if (columnName === ColumnIdToColumnNameMapping.mappingStatus) {
                    if (values[columnId] === AccountMappingStatus.MAPPED) {
                        return <StatusIndicator type="success">{AccountMappingStatus.MAPPED}</StatusIndicator>
                    } else {
                        return <StatusIndicator type="pending">{AccountMappingStatus.UNMAPPED}</StatusIndicator>
                    }
                } else if (columnName === ColumnIdToColumnNameMapping.updatedAt) {
                    return values[columnId] ? values[columnId].toISOString() : '-';
                } else {
                    return values[columnId] ?? '-';
                }
            },
            isRowHeader: true,
            sortingField: columnId,
            editConfig: getEditConfig(columnId),
        });
    });

    return columnDefinitions;
}

// These custom operators are based on UX requirements
function getFilteringOperators(columnName: string): (PropertyFilterOperator | PropertyFilterOperatorExtended<any>)[] {
    switch (columnName) {
        case ColumnIdToColumnNameMapping.accountName:
        case ColumnIdToColumnNameMapping.accountId:
        case ColumnIdToColumnNameMapping.parentAccountName:
        case ColumnIdToColumnNameMapping.parentAccountId:
            return ["=", ":"];
        case ColumnIdToColumnNameMapping.mappingStatus:
        case ColumnIdToColumnNameMapping.updatedBy:
            return ["="];
        case ColumnIdToColumnNameMapping.defaultMapping:
        case ColumnIdToColumnNameMapping.exceptionMapping:
            return ["=", "!="];
        case ColumnIdToColumnNameMapping.updatedAt:
            return [">=", "<="].map((operator) => {
                return {
                    operator: operator,
                    match: "datetime",
                    form: DateTimeForm,
                    format: formatDateTime,
                }
            })
        default:
            return ["=", ":", "!="];
    }
}

export default function ManageExceptionsCLIView(props: { services: ServiceCollection, selectedCli: string }) {
    const [getAccountMappingResult, getAccountMappingLoading, getAccountMappingError] = props.services.reportsService.getAccountMapping(AccountMappingType.CLI, props.selectedCli);
    const [accountMappingData, setAccountMappingData] = React.useState<AccountMappingRecord[]>([]);
    const [preferences, setPreferences] = React.useState<CollectionPreferencesProps.Preferences>({
        pageSize: 10,
    });
    const [displayOverrideWarningModal, setDisplayOverrideWarningModal] = React.useState(false);

    useEffect(() => {
        if (getAccountMappingResult) {
            setAccountMappingData(getAccountMappingResult.accountMappings.map((accountMapping: AccountMappingRecord) => {
                return {
                    ...accountMapping,
                    updatedAt: accountMapping.updatedAt ? new Date(accountMapping.updatedAt) : ''
                }
            }))
        }
    }, [getAccountMappingResult]);

    useEffect(() => {
        if (getAccountMappingError) {
            props.services.messageService.showErrorAutoDismissBanner(getAccountMappingError);
        }
    }, [getAccountMappingError]);

    const useCollectionResult = useCollection(
        accountMappingData,
        {
            propertyFiltering: {
                empty: <EmptyTableView message={NO_DATA_MESSAGE}/>,
                noMatch: <EmptyTableView message={NO_MATCH_MESSAGE}/>,
                filteringProperties: (Object.entries(ColumnIdToColumnNameMapping).map(([columnId, columnName]: [string, string]): PropertyFilterProperty => ({
                    key: columnId,
                    propertyLabel: columnName,
                    groupValuesLabel: `${columnName} Values`,
                    operators: getFilteringOperators(columnName),
                    // Defining defaultOperator is required to remove "=" from operators list for defaultUpdatedOn column
                    defaultOperator: columnName === ColumnIdToColumnNameMapping.updatedAt ? "<=" : "="
                }))),
                defaultQuery: {
                    tokens: [],
                    operation: "or"
                }
            },
            pagination: {
                pageSize: preferences.pageSize
            },
            sorting: {}
        }
    );

    const handleExceptionMappingUpdate = () => {
        // TODO: Add API call to update exception mapping
        setDisplayOverrideWarningModal(false);
    }

    return (
        <>
            <OverrideWarningModal
                visible={displayOverrideWarningModal}
                onCancel={() => {
                    setDisplayOverrideWarningModal(false);
                }}
                onConfirm={handleExceptionMappingUpdate}
                alertMessage={"There is existing default mapping for the selected account. Proceed to override the current\n" +
                    "configuration or click Cancel."}
            />

            {
                getAccountMappingLoading ? (
                        <Box textAlign="center">
                            <Spinner size="large"/>
                        </Box>
                    ) :
                    <BaseTableView header={props.selectedCli} columnDefinitions={prepareColumnDefinition()}
                                   preferences={preferences} setPreferences={setPreferences}
                                   useCollectionResult={useCollectionResult}
                        // TODO: Replace description with workbook that will come in API call
                        //            description={"CWB00149 - Last Mile Delivery Services"}

                    />
            }
        </>
    )
}