import ATPWebSocketApiClient from './ATPWebSocketApiClient';
import CONSTANTS from '../utils/constants';
import WebSocketApi from './WebSocketApi';

/**
 * Allows interaction with the Balance Pull Websocket API
 */
export default class RetriableATPWebSocketApiClient extends ATPWebSocketApiClient {
    private lastSentMessage?: any;
    private retryCount: number;
    private maxRetryCount: number = 3;
    public onMaxRetriesReached?: (action: any) => void; 
    
    constructor(authToken: string){
        super(authToken);
        this.retryCount = 0;
        this.addKeyListener(WebSocketApi.ConnectionEvent.ON_MESSAGE_SENT,(x:any) => {
            if (x == this.lastSentMessage){
                this.retryCount++;
            }
            else {
                this.retryCount = 0;
            }
            this.lastSentMessage = x;
        })
        this.addKeyListener(WebSocketApi.ConnectionEvent.ON_MESSAGE_RECEIVED, (x:any) => {
            try {
                const parsedMessage = JSON.parse(x as string);
                if (this.lastSentMessage != null && (parsedMessage.message?.indexOf("timed out") > 0 || x.isRetriable)){
                    if (this.retryCount < this.maxRetryCount){
                        super.sendMessage(this.lastSentMessage.action, this.lastSentMessage);
                    }
                    else if (this.retryCount >= this.maxRetryCount && this.onMaxRetriesReached) {
                        this.onMaxRetriesReached.call(this,this.lastSentMessage.action);
                    }    
                }
            }
            catch(error){
                console.error(error);
            }
            
        })
    }

    setOnMaxRetriesReached(handler:(action: any) => void){
        this.onMaxRetriesReached = handler;
    }
}