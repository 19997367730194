import {Button, ExpandableSection, SpaceBetween, Tabs} from "@amzn/awsui-components-react";
import TPTaxReportTableGrid from "src/components/reports/TPTaxReportTableGrid";
import React from "react";
import {ReportMetaData, TPTaxReportMapResponse} from "src/models/reports/TPTaxReport";
import ServiceCollection from "src/services/ServiceCollection";
import CONSTANTS from "src/utils/constants";
import {useHistory} from "react-router-dom";
import {MANAGE_EXCEPTIONS_TAB_URL_PREFIX} from "src/components/ic-account-mapping/constants";

export default function TPMultiCLIReportTableGrid(props: {
    reportData: TPTaxReportMapResponse,
    services: ServiceCollection,
    fetchReportLoading: boolean,
    reportMetaData?: ReportMetaData
}) {
    const history = useHistory();

    return (
        <SpaceBetween size={"xl"}>
            <ExpandableSection
                variant="container"
                headerText="Details"
                disableContentPaddings={true}
            >
                <TPTaxReportTableGrid columnHeaders={props.reportData?.reportHeaders}
                                      data={props.reportData?.reportContent?.DETAIL} services={props.services}
                                      loadingStatus={props.fetchReportLoading}/>
            </ExpandableSection>
            <ExpandableSection
                variant="container"
                headerText="TP Profit and Loss (P&L)"
                disableContentPaddings={true}
                headerActions={
                    (props.reportMetaData?.reportName === CONSTANTS.REPORT_TYPES.TP_TAX_CWB_REPORT && !!props.reportMetaData?.workbookLabel) ?
                        <Button onClick={() => {
                            history.push(`${MANAGE_EXCEPTIONS_TAB_URL_PREFIX}?workbookLabel=${encodeURIComponent(props.reportMetaData?.workbookLabel as string)}`)
                        }}> Manage P&L Mapping </Button> : null
                }
            >
                <TPTaxReportTableGrid columnHeaders={props.reportData?.reportHeaders}
                                      data={props.reportData?.reportContent?.TP_PNL} services={props.services}
                                      loadingStatus={props.fetchReportLoading}/>
            </ExpandableSection>
            <ExpandableSection
                variant="container"
                headerText="Profit Level Indicator (PLI)"
                disableContentPaddings={true}
                defaultExpanded={true}
            >
                <Tabs
                    tabs={[
                        {
                            label: "Return on Cost",
                            id: "NCP_PLI",
                            content: <TPTaxReportTableGrid columnHeaders={props.reportData?.reportHeaders}
                                                           data={props.reportData?.reportContent?.NCP_PLI}
                                                           services={props.services}
                                                           loadingStatus={props.fetchReportLoading}/>
                        },
                        {
                            label: "Return on Sales",
                            id: "ROS_PLI",
                            content: <TPTaxReportTableGrid columnHeaders={props.reportData?.reportHeaders}
                                                           data={props.reportData?.reportContent?.ROS_PLI}
                                                           services={props.services}
                                                           loadingStatus={props.fetchReportLoading}/>
                        },
                        {
                            label: "Berry Ratio",
                            id: "BR_PLI",
                            content: <TPTaxReportTableGrid columnHeaders={props.reportData?.reportHeaders}
                                                           data={props.reportData?.reportContent?.BR_PLI}
                                                           services={props.services}
                                                           loadingStatus={props.fetchReportLoading}/>
                        }
                    ]}
                    disableContentPaddings={true}
                />
            </ExpandableSection>
        </SpaceBetween>
    )
}