import { DbSortOrder } from "src/models/common/TPESearchRequest";
import { OpenCalcReviewsSearchRequest } from "src/models/dashboard/OpenCalculationReview";
import DASHBOARD_CONSTANTS from "./dashboardConstants";

export function getTopOpenCalcReviewsSearchPayload(): OpenCalcReviewsSearchRequest {
    return {
        page: 1,
        pageSize: DASHBOARD_CONSTANTS.TOP_N,
        sortField: DASHBOARD_CONSTANTS.OPEN_CALCULATION_REVIEW_FIELDS.DUE_DATE.DB_FIELD,
        sortOrder: DbSortOrder.ASCENDING
    };
}