import {
    PropertyFilterOperator,
    PropertyFilterOperatorExtended,
    PropertyFilterProperty,
    useCollection
} from "@amzn/awsui-collection-hooks";
import {
    Box,
    CollectionPreferencesProps, StatusIndicator
} from "@amzn/awsui-components-react";
import {
    InlineEditingHandlerAttributes, NO_DATA_MESSAGE, NO_MATCH_MESSAGE
} from "src/components/ic-account-mapping/constants";
import React, {useEffect} from "react";
import BaseTableView from "src/components/ic-account-mapping/shared/BaseTableView";
import {TableProps} from "@amzn/awsui-components-react/polaris/table";
import {formatDateTime} from "src/components/ic-account-mapping/Utils";
import EmptyTableView from "src/components/ic-account-mapping/shared/EmptyTableView";
import {Select} from "@amzn/awsui-components-react/polaris";
import {DateTimeForm} from "src/components/ic-account-mapping/shared/DateTimeForm";
import OverrideWarningModal from "src/components/ic-account-mapping/shared/OverrideWarningModal";
import ServiceCollection from "src/services/ServiceCollection";
import {
    AccountMappingRecord,
    AccountMappingStatus,
    AccountMappingType,
    AccountType
} from "src/models/reports/GetAccountMapping";
import Spinner from "@amzn/awsui-components-react/polaris/spinner";

const ColumnIdToColumnNameMapping = {
    accountId: "Account ID",
    accountName: "Account Name",
    parentAccountId: "Parent ID",
    parentAccountName: "Parent Name",
    mappingStatus: "Status",
    defaultMapping: "Default Mapping",
    updatedBy: "Default Updated By",
    updatedAt: "Default Updated On"
}

function getEditConfig(columnId: string): TableProps.EditConfig<any> | undefined {
    switch (columnId) {
        case ColumnIdToColumnNameMapping.defaultMapping:
            return {
                editingCell: (rowItem, {currentValue, setValue}) => {
                    const value = currentValue ?? rowItem.defaultMapping;
                    return (
                        <Select
                            autoFocus={true}
                            expandToViewport={true}
                            selectedOption={
                                Object.values(AccountType).map(value => ({
                                    label: value,
                                    value: value
                                })).find(
                                    option => option.value === value
                                ) ?? null
                            }
                            onChange={event => {
                                setValue(
                                    event.detail.selectedOption.value ??
                                    rowItem.defaultMapping
                                );
                            }}
                            options={Object.values(AccountType).map(value => ({
                                label: value,
                                value: value
                            }))}
                        />
                    );
                }
            };
        default:
            return undefined;
    }
}

function prepareColumnDefinition(): TableProps.ColumnDefinition<any>[] {
    const columnDefinitions: TableProps.ColumnDefinition<any>[] = [];
    Object.entries(ColumnIdToColumnNameMapping).forEach(([columnId, columnName]: [string, string]) => {
        columnDefinitions.push({
            id: columnId,
            header: columnName,
            cell: values => {
                if (columnName === ColumnIdToColumnNameMapping.mappingStatus) {
                    if (values[columnId] === AccountMappingStatus.MAPPED) {
                        return <StatusIndicator type="success">{AccountMappingStatus.MAPPED}</StatusIndicator>
                    } else {
                        return <StatusIndicator type="pending">{AccountMappingStatus.UNMAPPED}</StatusIndicator>
                    }
                } else if (columnName === ColumnIdToColumnNameMapping.updatedAt) {
                    return values[columnId] ? values[columnId].toISOString() : "-";
                } else {
                    return values[columnId] ?? "-";
                }
            },
            isRowHeader: true,
            sortingField: columnId,
            editConfig: getEditConfig(columnId),
        });
    });

    return columnDefinitions;
}

// These custom operators are based on UX requirements
function getFilteringOperators(columnName: string): (PropertyFilterOperator | PropertyFilterOperatorExtended<any>)[] {
    switch (columnName) {
        case ColumnIdToColumnNameMapping.accountName:
        case ColumnIdToColumnNameMapping.accountId:
        case ColumnIdToColumnNameMapping.parentAccountName:
        case ColumnIdToColumnNameMapping.parentAccountId:
            return ["=", ":"];
        case ColumnIdToColumnNameMapping.mappingStatus:
        case ColumnIdToColumnNameMapping.updatedBy:
            return ["="];
        case ColumnIdToColumnNameMapping.defaultMapping:
            return ["=", "!="];
        case ColumnIdToColumnNameMapping.updatedAt:
            return [">=", "<="].map((operator) => {
                return {
                    operator: operator,
                    match: 'datetime' as const,
                    form: DateTimeForm,
                    format: formatDateTime,
                }
            });
        default:
            return ["=", ":", "!="];
    }
}

export default function DefaultMappingSectionView(props: { services: ServiceCollection }) {
    const [preferences, setPreferences] = React.useState<CollectionPreferencesProps.Preferences>({
        pageSize: 10,
    });
    const [getAccountMappingResult, getAccountMappingLoading, getAccountMappingError] = props.services.reportsService.getAccountMapping(AccountMappingType.DEFAULT);
    const [accountMappingData, setAccountMappingData] = React.useState<AccountMappingRecord[]>([]);
    const [displayOverrideWarningModal, setDisplayOverrideWarningModal] = React.useState(false);
    const [inlineEditingHandlerAttributes, setInlineEditingHandlerAttributes] = React.useState<InlineEditingHandlerAttributes>({
        item: null,
        column: null,
        newValue: null
    });

    useEffect(() => {
        if (getAccountMappingResult) {
            setAccountMappingData(getAccountMappingResult.accountMappings.map((accountMapping: AccountMappingRecord) => {
                return {
                    ...accountMapping,
                    updatedAt: accountMapping.updatedAt ? new Date(accountMapping.updatedAt) : "-"
                }
            }))
        }
    }, [getAccountMappingResult]);

    useEffect(() => {
        if (getAccountMappingError) {
            props.services.messageService.showErrorAutoDismissBanner(getAccountMappingError);
        }
    }, [getAccountMappingError]);

    useEffect(() => {
        if (inlineEditingHandlerAttributes && inlineEditingHandlerAttributes.item) {
            if (inlineEditingHandlerAttributes.item.defaultMapping && inlineEditingHandlerAttributes.item.defaultMapping !== "-") {
                setDisplayOverrideWarningModal(true);
            } else {
                handleDefaultMappingUpdate();
            }
        }
    }, [inlineEditingHandlerAttributes]);

    const handleDefaultMappingUpdate = () => {
        // TODO: Add mapping update logic after API is configured for the same
        setDisplayOverrideWarningModal(false);
    }

    const useCollectionResult = useCollection(
        accountMappingData,
        {
            propertyFiltering: {
                empty: <EmptyTableView message={NO_DATA_MESSAGE}/>,
                noMatch: <EmptyTableView message={NO_MATCH_MESSAGE}/>,
                filteringProperties: (Object.entries(ColumnIdToColumnNameMapping).map(([columnId, columnName]: [string, string]): PropertyFilterProperty => ({
                    key: columnId,
                    propertyLabel: columnName,
                    groupValuesLabel: `${columnName} Values`,
                    operators: getFilteringOperators(columnName),
                    // Defining defaultOperator is required to remove "=" from operators list for defaultUpdatedOn column
                    defaultOperator: columnName === ColumnIdToColumnNameMapping.updatedAt ? "<=" : "="
                }))),
                defaultQuery: {
                    tokens: [],
                    operation: "or"
                }
            },
            pagination: {
                pageSize: preferences.pageSize
            },
            sorting: {}
        }
    );

    return (
        <>
            <OverrideWarningModal
                visible={displayOverrideWarningModal}
                onCancel={() => {
                    setDisplayOverrideWarningModal(false);
                }}
                onConfirm={handleDefaultMappingUpdate}
                alertMessage={"There is existing default mapping for the selected account. Proceed to override the current\n" +
                    " configuration or click Cancel."}
            />

            {
                getAccountMappingLoading ? (
                        <Box textAlign="center">
                            <Spinner size="large"/>
                        </Box>
                    ) :
                    <BaseTableView
                        header={"All Accounts"}
                        useCollectionResult={useCollectionResult}
                        preferences={preferences}
                        setPreferences={setPreferences}
                        columnDefinitions={prepareColumnDefinition()}
                        setInlineEditingHandlerAttributes={setInlineEditingHandlerAttributes}
                    />
            }
        </>
    );
}