import React from "react";
import {Alert, Box, Modal} from "@amzn/awsui-components-react/polaris";
import {Button, SpaceBetween} from "@amzn/awsui-components-react";

interface OverrideWarningModalProps {
    visible: boolean;
    onCancel: () => void;
    onConfirm: () => void;
    alertMessage: string;
}

export default function OverrideWarningModal(props: OverrideWarningModalProps) {
    return (
        <Modal
            visible={props.visible}
            footer={
                <Box float="right">
                    <SpaceBetween direction="horizontal" size="xs">
                        <Button onClick={props.onCancel} variant={"link"}>Cancel</Button>
                        <Button onClick={props.onConfirm}>
                            Override
                        </Button>
                    </SpaceBetween>
                </Box>
            }
            onDismiss={props.onCancel}
            header={"Override existing default mapping"}
        >
            <Alert statusIconAriaLabel="Warning" type="warning">
                {`${props.alertMessage}`}
            </Alert>
        </Modal>
    );
}