import { StatusIndicator } from '@amzn/awsui-components-react';
import React, { useMemo } from 'react';

export default function TaskStatusIndicator(props: {status: string}) {
    const { status = '' } = props;

    const getStatusIcon = () => {
        switch (status) {
            case 'Overdue': return 'warning';
            case 'In Progress': return 'in-progress';
            case 'Pending': return 'pending';
            case 'Completed': return 'success';
            default: return null;
        }
    }

    const getColorOverride = () => {
        return status == 'Overdue' ? 'red' : undefined;
    }
    
    const statusIcon = useMemo(getStatusIcon, [status]);
    const colorOverride = useMemo(getColorOverride, [status]);

    return  statusIcon == null
            ?
                <span>{status || ' '}</span>
            :
                <StatusIndicator type={statusIcon} colorOverride={colorOverride}>{status}</StatusIndicator>;
}