import React, {useEffect} from "react";
import {
    InlineEditingHandlerAttributes, MANAGE_EXCEPTIONS_TAB_URL_PREFIX,
    NO_DATA_MESSAGE,
    NO_MATCH_MESSAGE
} from "src/components/ic-account-mapping/constants";
import {
    Box,
    Button,
    CollectionPreferencesProps,
    Multiselect,
    Select, SelectProps,
    StatusIndicator
} from "@amzn/awsui-components-react";
import {
    PropertyFilterOperator,
    PropertyFilterOperatorExtended,
    PropertyFilterProperty,
    useCollection
} from "@amzn/awsui-collection-hooks";
import EmptyTableView from "src/components/ic-account-mapping/shared/EmptyTableView";
import {formatDateTime} from "src/components/ic-account-mapping/Utils";
import {DateTimeForm} from "src/components/ic-account-mapping/shared/DateTimeForm";
import {TableProps} from "@amzn/awsui-components-react/polaris/table";
import {Alert, FormField, Link, Modal} from "@amzn/awsui-components-react/polaris";
import BaseTableView from "src/components/ic-account-mapping/shared/BaseTableView";
import ServiceCollection from "src/services/ServiceCollection";
import {
    AccountMappingRecord,
    AccountMappingStatus,
    AccountMappingType,
    AccountType
} from "src/models/reports/GetAccountMapping";
import Spinner from "@amzn/awsui-components-react/polaris/spinner";
import {GetFiscalYearActiveCLIsForWorkbookRequest} from "src/models/reports/GetFiscalYearActiveCLIsForWorkbook";

const ColumnIdToColumnNameMapping = {
    accountId: "Account ID",
    accountName: "Account Name",
    parentAccountId: "Parent ID",
    parentAccountName: "Parent Name",
    mappingStatus: "Status",
    defaultMapping: "Default Mapping",
    exceptionMapping: "Exception Mapping",
    exclusionSet: "CLI Exclusions",
    updatedBy: "Exception Updated By",
    updatedAt: "Exception Updated On"
}

function getEditConfig(columnId: string): TableProps.EditConfig<any> | undefined {
    switch (columnId) {
        case ColumnIdToColumnNameMapping.exceptionMapping:
            return {
                editingCell: (rowItem, {currentValue, setValue}) => {
                    const value = currentValue ?? rowItem.exceptionMapping;
                    return (
                        <Select
                            autoFocus={true}
                            expandToViewport={true}
                            selectedOption={
                                Object.values(AccountType).map(value => ({
                                    label: value,
                                    value: value
                                })).find(
                                    option => option.value === value
                                ) ?? null
                            }
                            onChange={event => {
                                setValue(
                                    event.detail.selectedOption.value ??
                                    rowItem.exceptionMapping
                                );
                            }}
                            options={Object.values(AccountType).map(value => ({
                                label: value,
                                value: value
                            }))}
                        />
                    );
                }
            };
        default:
            return undefined;
    }
}

// These custom operators are based on UX requirements
function getFilteringOperators(columnName: string): (PropertyFilterOperator | PropertyFilterOperatorExtended<any>)[] {
    switch (columnName) {
        case ColumnIdToColumnNameMapping.accountName:
        case ColumnIdToColumnNameMapping.accountId:
        case ColumnIdToColumnNameMapping.parentAccountName:
        case ColumnIdToColumnNameMapping.parentAccountId:
            return ["=", ":"];
        case ColumnIdToColumnNameMapping.mappingStatus:
        case ColumnIdToColumnNameMapping.updatedBy:
        case ColumnIdToColumnNameMapping.exclusionSet:
            return ["="];
        case ColumnIdToColumnNameMapping.defaultMapping:
        case ColumnIdToColumnNameMapping.exceptionMapping:
            return ["=", "!="];
        case ColumnIdToColumnNameMapping.updatedAt:
            return [">=", "<="].map((operator) => {
                return {
                    operator: operator,
                    match: "datetime",
                    form: DateTimeForm,
                    format: formatDateTime,
                }
            })
        default:
            return ["=", ":", "!="];
    }
}

export default function ManageExceptionsWorkbookView(props: {
    services: ServiceCollection,
    selectedWorkbookId: string,
    selectedWorkbookLabel: string
}) {
    const [getAccountMappingResult, getAccountMappingLoading, getAccountMappingError] = props.services.reportsService.getAccountMapping(AccountMappingType.WORKBOOK, props.selectedWorkbookId);
    const [getActiveCLIsForWorkbookRequestPayload, setGetActiveCLIsForWorkbookRequestPayload] = React.useState(null as GetFiscalYearActiveCLIsForWorkbookRequest | null);
    const [getFiscalYearActiveCLIsForWorkbookResult, getFiscalYearActiveCLIsForWorkbookLoading, getFiscalYearActiveCLIsForWorkbookError] =
        props.services.reportsService.getFiscalYearActiveCLIsForWorkbook(getActiveCLIsForWorkbookRequestPayload);
    const [accountMappingData, setAccountMappingData] = React.useState<AccountMappingRecord[]>([]);
    const [preferences, setPreferences] = React.useState<CollectionPreferencesProps.Preferences>({
        pageSize: 10,
    });
    const [displayEditExceptionMappingModal, setDisplayEditExceptionMappingModal] = React.useState(false);
    const [displayCliExclusionsModal, setDisplayCliExclusionsModal] = React.useState(false);
    const [excludedCliList, setExcludedCliList] = React.useState<string[]>([]);
    const [inlineEditingHandlerAttributes, setInlineEditingHandlerAttributes] = React.useState<InlineEditingHandlerAttributes>({
        item: null,
        column: null,
        newValue: null
    });
    const [selectedCliExclusions, setSelectedCliExclusions] = React.useState<SelectProps.Option[]>([]);

    function prepareColumnDefinition(): TableProps.ColumnDefinition<any>[] {
        const columnDefinitions: TableProps.ColumnDefinition<any>[] = [];
        Object.entries(ColumnIdToColumnNameMapping).forEach(([columnId, columnName]: [string, string]) => {
            columnDefinitions.push({
                id: columnId,
                header: columnName,
                cell: values => {
                    if (columnName === ColumnIdToColumnNameMapping.mappingStatus) {
                        if (values[columnId] === AccountMappingStatus.MAPPED) {
                            return <StatusIndicator type="success">{AccountMappingStatus.MAPPED}</StatusIndicator>
                        } else {
                            return <StatusIndicator type="pending">{AccountMappingStatus.UNMAPPED}</StatusIndicator>
                        }
                    } else if (columnName === ColumnIdToColumnNameMapping.updatedAt) {
                        return values[columnId] ? values[columnId].toISOString() : "-";
                    } else if (columnName === ColumnIdToColumnNameMapping.exclusionSet) {
                        return values[columnId] ? <Link onClick={() => {
                            setExcludedCliList(values[columnId]);
                            setDisplayCliExclusionsModal(true)
                        }}> View </Link> : '-';
                    } else {
                        return values[columnId] ?? '-';
                    }
                },
                isRowHeader: true,
                sortingField: columnId,
                editConfig: getEditConfig(columnName),
            });
        });

        return columnDefinitions;
    }

    useEffect(() => {
        setGetActiveCLIsForWorkbookRequestPayload({
            workbookId: props.selectedWorkbookId
        })
    }, [props.selectedWorkbookId]);

    useEffect(() => {
        if (inlineEditingHandlerAttributes && inlineEditingHandlerAttributes.item) {
            setDisplayEditExceptionMappingModal(true);
        }
    }, [inlineEditingHandlerAttributes]);

    useEffect(() => {
        if (getAccountMappingResult) {
            setAccountMappingData(getAccountMappingResult.accountMappings.map((accountMapping: AccountMappingRecord) => {
                return {
                    ...accountMapping,
                    updatedAt: accountMapping.updatedAt ? new Date(accountMapping.updatedAt) : ''
                }
            }))
        }
    }, [getAccountMappingResult]);

    useEffect(() => {
        if (getAccountMappingError || getFiscalYearActiveCLIsForWorkbookError) {
            props.services.messageService.showErrorAutoDismissBanner(getAccountMappingError || getFiscalYearActiveCLIsForWorkbookError);
        }
    }, [getAccountMappingError, getFiscalYearActiveCLIsForWorkbookError]);

    const useCollectionResult = useCollection(
        accountMappingData,
        {
            propertyFiltering: {
                empty: <EmptyTableView message={NO_DATA_MESSAGE}/>,
                noMatch: <EmptyTableView message={NO_MATCH_MESSAGE}/>,
                filteringProperties: (Object.entries(ColumnIdToColumnNameMapping).map(([columnId, columnName]: [string, string]): PropertyFilterProperty => ({
                    key: columnId,
                    propertyLabel: columnName,
                    groupValuesLabel: `${columnName} Values`,
                    operators: getFilteringOperators(columnName),
                    // Defining defaultOperator is required to remove "=" from operators list for defaultUpdatedOn column
                    defaultOperator: columnName === ColumnIdToColumnNameMapping.updatedAt ? "<=" : "="
                }))),
                defaultQuery: {
                    tokens: [],
                    operation: "or"
                }
            },
            pagination: {
                pageSize: preferences.pageSize
            },
            sorting: {}
        }
    );

    const checkIfCliExclusionsExist = (): boolean => {
        return (inlineEditingHandlerAttributes && inlineEditingHandlerAttributes.item && inlineEditingHandlerAttributes.item.exclusionSet && inlineEditingHandlerAttributes.item.exclusionSet.length !== 0);
    }

    return <>
        {/* TODO: Add update actions to this modal along with Update API Integration */}
        <Modal visible={displayEditExceptionMappingModal}
               onDismiss={() => setDisplayEditExceptionMappingModal(false)} header={"Edit Exception Mapping"}
               footer={
                   <Box float={"right"}>
                       <Button variant="link"
                               onClick={() => setDisplayEditExceptionMappingModal(false)}>Cancel</Button>
                       {
                           checkIfCliExclusionsExist() ? <>
                               <Button onClick={() => {
                                   setDisplayEditExceptionMappingModal(false)
                               }}>Proceed and Override </Button>
                               <Button onClick={() => {
                                   setDisplayEditExceptionMappingModal(false)
                               }}>Proceed with Exclusion</Button>
                           </> : <Button onClick={() => setDisplayEditExceptionMappingModal(false)}
                                         variant={"primary"}>Confirm</Button>
                       }
                   </Box>
               }
        >
            {
                checkIfCliExclusionsExist() && <Alert statusIconAriaLabel="Warning" type="warning">
                    The below CLI/CLIs have existing exception mapping. Override the existing mapping or exclude
                    them from the update, so they will keep the existing mapping
                    <br/>
                    {inlineEditingHandlerAttributes.item.exclusionSet.map((cli: string) => (
                        <>
                            <Link key={cli}
                                  onFollow={() => window.open(`${MANAGE_EXCEPTIONS_TAB_URL_PREFIX}?cli=${cli}`)}>{cli}</Link>
                            <br/>
                        </>
                    ))}
                </Alert>
            }
            <p>You are applying the selected account exception mapping to all CLIs in this CWB. Setup exclusion if
                needed or leave it blank.</p>
            <FormField label={<>Exclude below CLIs - <i>optional</i></>}>
                <Multiselect filteringType={"auto"}
                             keepOpen={true}
                             placeholder={"Select CLI/CLIs"}
                             options={(getFiscalYearActiveCLIsForWorkbookLoading || getFiscalYearActiveCLIsForWorkbookError ||
                                 !getFiscalYearActiveCLIsForWorkbookResult) ?
                                 [] : getFiscalYearActiveCLIsForWorkbookResult.clis.map((cli: string) => ({
                                     label: cli,
                                     value: cli
                                 }))}
                             selectedOptions={selectedCliExclusions}
                             expandToViewport
                             onChange={({detail}) => setSelectedCliExclusions([...detail.selectedOptions])}/>
            </FormField>
        </Modal>

        <Modal visible={displayCliExclusionsModal} onDismiss={() => setDisplayCliExclusionsModal(false)}
               footer={
                   <Box float="right">
                       <Button variant="link" onClick={() => setDisplayCliExclusionsModal(false)}>Cancel</Button>
                   </Box>
               } header={"CLI Exclusions"}>
            <p>Below are the CLIs part of this CWB which don't follow default mappings for this account.</p>
            {excludedCliList.map((cli) => (
                <>
                    <Link key={cli}
                          onFollow={() => window.open(`${MANAGE_EXCEPTIONS_TAB_URL_PREFIX}?cli=${cli}`)}>{cli}</Link>
                    <br/>
                </>
            ))}
        </Modal>

        {getAccountMappingLoading ? <Box textAlign={"center"}> <Spinner size="large"/> </Box> :
            <BaseTableView header={`${props.selectedWorkbookLabel}`} columnDefinitions={prepareColumnDefinition()}
                           preferences={preferences} setPreferences={setPreferences}
                           useCollectionResult={useCollectionResult}
                           setInlineEditingHandlerAttributes={setInlineEditingHandlerAttributes}/>}
    </>;
}