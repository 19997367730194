import AgreementsService from "./agreements/AgreementsService";
import WorklistsService from "./agreements/WorklistsService";
import CalculationsService from "./calculations/CalculationsService";
import DashboardService from "./dashboard/DashboardService";
import MessageService from "./utils/MessageService";
import UsersService from "./users/UsersService";
import CalculationBuilderService from "src/services/calculation-builder/CalculationBuilderService";
import DataSourcesService from "src/services/calculation-builder/DataSourcesService";
import FormattingService from './utils/FormattingService';
import ReadOnlyCalculationService from "src/services/calculations/ReadOnlyCalculationService";
import CalculationStepsService from "src/services/calculation-builder/CalculationStepsService";
import ATPWebSocketApiClient from "src/services/ATPWebSocketApiClient";
import RetriableATPWebSocketApiClient from "src/services/RetriableATPWebSocketApiClient";
import JournalsService from "./calculation-builder/JournalsService";
import CustomDataTablesService from "src/services/custom-data-tables/CustomDataTablesService";
import CustomCOAService from "./custom-coa/CustomCOAService";
import CalculationUploadService from "src/services/calculation-upload-template/CalculationUploadService";
import ICRSService from "./icrs/ICRSService";
import ReportsService from "src/services/reports/ReportsService";
import TPAllocationService from "./tp-allocation/TPAllocationService";
import TPAllocationDatasetService from "src/services/tp-allocation/data-set/DatasetService";
import TPAllocationTotalsService from "src/services/tp-allocation/totals/WorksheetTotalsService";
import TPAllocationGroupsService from "src/services/tp-allocation/allocation-groups/AllocationGroupsService";
import PlaceholdersService from "src/services/tp-allocation/placeholders/PlaceholdersService";
import MECService from "src/services/mec/MECService";
import StandardAllocationService from "src/services/calculation-builder/StandardAllocationService";
import CLIService from "src/services/common/CLIService";
import PeriodChargesService from "src/services/period-charges/PeriodChargesService";
import NotificationService from "src/services/notifications/NotificationService";
import { UserIdentity } from "src/models/users/UserIdentity";


/**
 * Use this class to add services that you want to be available to a context
 */
export default class ServiceCollection {
    dashboardService: DashboardService;
    agreementsService: AgreementsService;
    calculationsService: CalculationsService;
    icrsService: ICRSService;
    calculationBuilderService: CalculationBuilderService;
    worklistService: WorklistsService;
    messageService: MessageService;
    usersService: UsersService;
    formattingService: FormattingService;
    readOnlyCalculationService : ReadOnlyCalculationService;
    calculationStepsService : CalculationStepsService;
    atpWebSocketApiService: ATPWebSocketApiClient;
    dataSourcesService: DataSourcesService;
    journalsService: JournalsService;
    customDataTablesService: CustomDataTablesService;
    customCOAService: CustomCOAService;
    calculationUploadService: CalculationUploadService;
    reportsService: ReportsService;
    retriableWebSocketService: RetriableATPWebSocketApiClient;
    tpAllocationService: TPAllocationService;
    tpAllocationDatasetService: TPAllocationDatasetService;
    tpAllocationTotalsService: TPAllocationTotalsService;
    tpAllocationGroupsService: TPAllocationGroupsService;
    tpAllocationPlaceholdersService: PlaceholdersService;
    mecService: MECService;
    notificationService: NotificationService;
    standardAllocationService: StandardAllocationService;
    cliService: CLIService;
    periodChargesService: PeriodChargesService;

    constructor(userIdentity?: UserIdentity) {
        this.agreementsService = new AgreementsService();
        this.dashboardService = new DashboardService();
        this.calculationsService = new CalculationsService();
        this.icrsService = new ICRSService();
        this.calculationBuilderService = new CalculationBuilderService();
        this.messageService = new MessageService();
        this.worklistService = new WorklistsService();
        this.usersService = new UsersService();
        this.formattingService = new FormattingService();
        this.readOnlyCalculationService = new ReadOnlyCalculationService();
        this.calculationStepsService = new CalculationStepsService();
        this.atpWebSocketApiService = new ATPWebSocketApiClient(userIdentity?.token);
        this.dataSourcesService = new DataSourcesService();
        this.journalsService = new JournalsService();
        this.customDataTablesService = new CustomDataTablesService();
        this.customCOAService = new CustomCOAService();
        this.calculationUploadService = new CalculationUploadService();
        this.reportsService = new ReportsService();
        this.retriableWebSocketService = new RetriableATPWebSocketApiClient(userIdentity?.token);
        this.tpAllocationService = new TPAllocationService();
        this.tpAllocationDatasetService = new TPAllocationDatasetService();
        this.tpAllocationTotalsService = new TPAllocationTotalsService();
        this.tpAllocationGroupsService = new TPAllocationGroupsService();
        this.tpAllocationPlaceholdersService = new PlaceholdersService();
        this.mecService = new MECService();
        this.notificationService = new NotificationService();
        this.standardAllocationService = new StandardAllocationService();
        this.cliService = new CLIService();
        this.periodChargesService = new PeriodChargesService();
    }
}