import { Container, Grid, Header, KeyValuePairs, StatusIndicator } from '@amzn/awsui-components-react';
import React from 'react';
import { DashboardSummary } from 'src/models/dashboard/DashboardSummary';
import { TPELoadingSpinner } from '../shared/TPELoadingSpinner';

export default function ActionItemsSummary(props: { dashboardSummaryResult: DashboardSummary, dashboardSummaryLoading: boolean }) {
  const { dashboardSummaryResult, dashboardSummaryLoading } = props;
  return <Grid
      gridDefinition={[
      { colspan: { default: 3, xxs: 9 } },
      { colspan: { default: 9, xxs: 3 } }
      ]}
    >
      <Container
        data-class="summaryContainer"
        header={
          <Header description="Relevant to me">
            Summary
          </Header>
        }
      >
        <TPELoadingSpinner loading={dashboardSummaryLoading}>
          <KeyValuePairs
            columns={4}
            items={[
              { label: "Total items", value: <div className="displayLargeBold">{dashboardSummaryResult?.totalItems}</div> },
              { label: "Open items", value: <div className="displayLargeBold">{dashboardSummaryResult?.openItems}</div> },
              { label: "Overdue", value: <StatusIndicator data-class="dashboardSummaryStatusIndicator" type="warning" colorOverride="red"><span className="displayLargeBold">{dashboardSummaryResult?.overdueItems}</span></StatusIndicator> },
              { label: "Due soon", value: <StatusIndicator data-class="dashboardSummaryStatusIndicator" type="warning"><span className="displayLargeBold">{dashboardSummaryResult?.dueSoonItems}</span></StatusIndicator> }
            ]}
          />
        </TPELoadingSpinner>
      </Container>
      <Container
        data-class="summaryContainer"
        header={
            <Header>Completion</Header>
        }
      >
        <TPELoadingSpinner loading={dashboardSummaryLoading}>
          <div className="completionDiv">
            <span className="displayLargeBold">{dashboardSummaryResult?.percentageCompletion}%</span>
            <div>of total items have been completed</div>
          </div>
        </TPELoadingSpinner>
    </Container>
  </Grid>
}