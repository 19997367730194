import {TableProps} from "@amzn/awsui-components-react/polaris/table";

/**
 * TODO:
 * 1. This file currently contains static test data which will be removed once backend is integrated
 *     and actual data is fetched from backend
 * 2. This file also contains interfaces and enums which will be moved to separate files if they are required along with
 *     API interfaces
 */

export interface InlineEditingHandlerAttributes {
    item: any,
    column: TableProps.ColumnDefinition<any> | null,
    newValue: any
}

export enum COLUMNS_FOR_ACTIVITY_LOG_TABLE {
    ACCOUNT_ID = "accountId",
    ITEM = "item",
    EVENT = "event",
    OLD_VALUE = "oldValue",
    NEW_VALUE = "newValue",
    PERFORMED_BY = "performedBy",
    TIMESTAMP = "timestamp"
}

export var TEST_DATA_FOR_ACTIVITY_LOG = [{
    accountId: "81103",
    item: "All CLI",
    event: "Default Update",
    oldValue: "Total I/C Opex",
    newValue: "Total I/C Revenue",
    performedBy: "system",
    timestamp: new Date(1741428676000)
}, {
    accountId: "81100",
    item: "X CLI",
    event: "Exception Update",
    oldValue: "Total I/C Opex",
    newValue: "Total I/C Cogs",
    performedBy: "vanshl",
    timestamp: new Date(1741428676000)
}, {
    accountId: "81105",
    item: "Y Workbook",
    event: "Exception Update",
    oldValue: "Total I/C Revenue",
    newValue: "Total I/C Cogs",
    performedBy: "vanshl",
    timestamp: new Date(1741428676000)
}, {
    accountId: "81109",
    item: "P Workbook",
    event: "Default Update",
    oldValue: "-",
    newValue: "Total I/C Cogs",
    performedBy: "system",
    timestamp: new Date(1741428676000)
}, {
    accountId: "81100",
    item: "Q CLI",
    event: "Default Update",
    oldValue: "-",
    newValue: "Total I/C Opex",
    performedBy: "system",
    timestamp: new Date(1741428676000)
}]

export const NO_DATA_MESSAGE: string = "No data to display"

export const NO_MATCH_MESSAGE: string = "No match found"

export const MANAGE_EXCEPTIONS_TAB_URL_PREFIX: string = "/icAccountMapping/manageExceptions"