import { Table, Box, SpaceBetween, Link } from '@amzn/awsui-components-react/polaris';
import React, { useMemo, useState } from 'react';
import TaskStatusIndicator from '../shared/TaskStatusIndicator';
import CONSTANTS from 'src/utils/constants';
import TPENavigator from '../TPENavigator';
import DASHBOARD_CONSTANTS from './dashboardConstants';
import { OpenRequest, OpenRequestsResult } from 'src/models/dashboard/OpenRequest';

export default function OpenRequestsGrid(props: { openRequestsResult: OpenRequestsResult, isLoading: boolean, dashboardView?: boolean }) {
  const { openRequestsResult, isLoading, dashboardView = false } = props;

  const [navigateURL, setNavigateURL] = useState('');

  const navigateToCalcBuilder = (calculationNumber: string, recordId: string | undefined) => {
    if (recordId != null) {
        setNavigateURL(CONSTANTS.PAGE_NAV.CALCULATION_BUILDER.URL + '/' + btoa(recordId));
    } else {
        setNavigateURL(CONSTANTS.PAGE_NAV.ICRS_CALCULATION_BUILDER.URL + '/' + btoa(calculationNumber));
    }
  }

  const colDefs: any[] = useMemo(() => [
      {
          id: DASHBOARD_CONSTANTS.OPEN_REQUEST_FIELDS.ENTITY_ID.ACCESSOR,
          header: DASHBOARD_CONSTANTS.OPEN_REQUEST_FIELDS.ENTITY_ID.HEADER,
          cell: (item: OpenRequest) => <Link href="#" onFollow={() => navigateToCalcBuilder(item.entityId, item.recordId)}>{item.entityId}</Link>,
          width: '20%'
      },
      {
          id: DASHBOARD_CONSTANTS.OPEN_REQUEST_FIELDS.ENTITY_TYPE.ACCESSOR,
          header: DASHBOARD_CONSTANTS.OPEN_REQUEST_FIELDS.ENTITY_TYPE.HEADER,
          cell: (item: OpenRequest) => (DASHBOARD_CONSTANTS.ENTITY_TYPE_TO_DISPLAY_MAP as any)[item.entityType],
          width: '20%'
      },
      {
          id: DASHBOARD_CONSTANTS.OPEN_REQUEST_FIELDS.TASK_STATUS.ACCESSOR,
          header: DASHBOARD_CONSTANTS.OPEN_REQUEST_FIELDS.TASK_STATUS.HEADER,
          cell: (item: OpenRequest) => <TaskStatusIndicator status={item.taskStatus} />,
          width: '20%'
      },
      {
          id: DASHBOARD_CONSTANTS.OPEN_REQUEST_FIELDS.DUE_DATE.ACCESSOR,
          header: DASHBOARD_CONSTANTS.OPEN_REQUEST_FIELDS.DUE_DATE.HEADER,
          cell: (item: OpenRequest) => item.dueDateDisplay,
          width: '20%'
      },
      {
          id: DASHBOARD_CONSTANTS.OPEN_REQUEST_FIELDS.APPROVER.ACCESSOR,
          header: DASHBOARD_CONSTANTS.OPEN_REQUEST_FIELDS.APPROVER.HEADER,
          cell: (item: OpenRequest) => item.approver,
          width: '20%'
      }
  ], [openRequestsResult]);


  return (
    <>
    <TPENavigator path={navigateURL} />
    <Table
        columnDefinitions={colDefs}
        enableKeyboardNavigation
        wrapLines={true}
        items={openRequestsResult?.items || []}
        loadingText="Loading"
        loading={isLoading}
        empty={
            <Box
            margin={{ vertical: "xs" }}
            textAlign="center"
            color="inherit"
            >
            <SpaceBetween size="m">
                <b>No open requests</b>
                <span>All requests have been completed</span>
            </SpaceBetween>
            </Box>
        }
        variant={dashboardView ? 'embedded' : 'container'}
      />
      </>
    )
}