import { useEffect, useState } from 'react';
import { OpenCalculationReviewsResult, OpenCalcReviewsSearchRequest } from 'src/models/dashboard/OpenCalculationReview';

import apiService from '../ApiCallService';
import ErrorUtils from 'src/utils/ErrorUtils';
import DateUtils from 'src/utils/dateUtils';
import { OpenApprovalsResult, OpenApprovalsSearchRequest } from 'src/models/dashboard/OpenApproval';
import { OpenRequestsResult, OpenRequestsSearchRequest } from 'src/models/dashboard/OpenRequest';
import { DashboardSummary } from 'src/models/dashboard/DashboardSummary';
import StringUtils from 'src/utils/stringUtils';

/**
 * This class should only contain the API calls used in the dashboard
 */
export default class DashboardService {

    getActionItemsSummary(query: string) : [DashboardSummary, boolean, string] {
        const [results, setResults] = useState(null as unknown as DashboardSummary);
        const [loading, setLoading] = useState(false);
        const [error, setError] = useState('');

        useEffect(() => {
            async function fetchData() {
                try {
                    setLoading(true);
                    //const response = await apiService.getActionItemsSummary();
                    //const json : DashboardSummary = await response.json();
                    //setResults(json);
                    setResults({
                        totalItems: 0,
                        openItems: 0,
                        overdueItems: 0,
                        dueSoonItems: 0,
                        percentageCompletion: 100
                    })
                }
                catch (ex) {
                    setError(ErrorUtils.getMessage(ex));
                }
                finally {
                    setLoading(false);
                }
            }

            if (!StringUtils.isNullOrEmpty(query)) {
                fetchData();
            }
        }, [query]);
        return [results, loading, error];
    }

    searchOpenCalculationReviews(payload: OpenCalcReviewsSearchRequest | undefined) : [OpenCalculationReviewsResult, boolean, string] {
        const [results, setResults] = useState(null as unknown as OpenCalculationReviewsResult);
        const [loading, setLoading] = useState(false);
        const [error, setError] = useState('');

        useEffect(() => {
            async function fetchData() {
                try {
                    setLoading(true);
                    const response = await apiService.searchOpenCalculationReviews(payload);
                    const json : OpenCalculationReviewsResult = await response.json();
                    if (json.items != null) {
                        json.items.forEach(i => i.dueDateDisplay = DateUtils.formatDate(i.dueDate, 'YYYY-MM-DD'));
                    }
                    setResults(json);
                }
                catch (ex) {
                    setError(ErrorUtils.getMessage(ex));
                }
                finally {
                    setLoading(false);
                }
            }

            if (payload != null) {
                fetchData();
            }
        }, [payload]);
        return [results, loading, error];
    }

    searchOpenApprovals(payload: OpenApprovalsSearchRequest | undefined) : [OpenApprovalsResult, boolean, string] {
        const [results, setResults] = useState(null as unknown as OpenApprovalsResult);
        const [loading, setLoading] = useState(false);
        const [error, setError] = useState('');

        useEffect(() => {
            async function fetchData() {
                try {
                    setLoading(true);
                    /*const response = await apiService.searchOpenApprovals(payload);
                    const json : OpenApprovalsResult = await response.json();
                    if (json.items != null) {
                        json.items.forEach(i => i.dueDateDisplay = DateUtils.formatDate(i.dueDate, 'YYYY-MM-DD'));
                    }
                    setResults(json);*/
                    setResults(getTempApprovalsResult());
                }
                catch (ex) {
                    setError(ErrorUtils.getMessage(ex));
                }
                finally {
                    setLoading(false);
                }
            }

            if (payload != null) {
                fetchData();
            }
        }, [payload]);
        return [results, loading, error];
    }

    searchOpenRequests(payload: OpenRequestsSearchRequest | undefined) : [OpenRequestsResult, boolean, string] {
        const [results, setResults] = useState(null as unknown as OpenRequestsResult);
        const [loading, setLoading] = useState(false);
        const [error, setError] = useState('');

        useEffect(() => {
            async function fetchData() {
                try {
                    setLoading(true);
                    /*const response = await apiService.searchOpenRequests(payload);
                    const json : OpenApprovalsResult = await response.json();
                    if (json.items != null) {
                        json.items.forEach(i => i.dueDateDisplay = DateUtils.formatDate(i.dueDate, 'YYYY-MM-DD'));
                    }
                    setResults(json);*/
                    setResults(getTempRequestsResult());
                }
                catch (ex) {
                    setError(ErrorUtils.getMessage(ex));
                }
                finally {
                    setLoading(false);
                }
            }

            if (payload != null) {
                fetchData();
            }
        }, [payload]);
        return [results, loading, error];
    }
}

function getTempApprovalsResult() : OpenApprovalsResult{
    const result: OpenApprovalsResult = {
        page: 1,
        pageSize: 5,
        totalSize: 0,
        pagesCount: 2,
        sortField: null as unknown as any,
        sortOrder: null as unknown as any,
        items: [
            /*{
                entityId: 'CLI1',
                entityType: 'CALCULATION',
                taskStatus: 'Overdue',
                dueDate: 123,
                dueDateDisplay: '2025-03-12',
                requester: 'smith'
            },
            {
                entityId: 'CLI2',
                entityType: 'CALCULATION',
                taskStatus: 'Pending',
                dueDate: 123,
                dueDateDisplay: '2025-03-28',
                requester: 'smith'
            }*/
        ]
    }
    return result;
}

function getTempRequestsResult() : OpenRequestsResult{
    const result: OpenRequestsResult = {
        page: 1,
        pageSize: 5,
        totalSize: 0,
        pagesCount: 2,
        sortField: null as unknown as any,
        sortOrder: null as unknown as any,
        items: [
            /*{
                entityId: 'CLI1',
                entityType: 'CALCULATION',
                taskStatus: 'Overdue',
                dueDate: 123,
                dueDateDisplay: '2025-03-12',
                approver: 'rajavini'
            },
            {
                entityId: 'CLI2',
                entityType: 'CALCULATION',
                taskStatus: 'Pending',
                dueDate: 123,
                dueDateDisplay: '2025-03-28',
                approver: 'rajavini'
            }*/
        ]
    }
    return result;
}
