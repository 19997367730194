import {Box, SpaceBetween} from "@amzn/awsui-components-react";
import React from "react";

export default function EmptyTableView(props: { message: string }) {
    return (
        <Box
            margin={{vertical: "xs"}}
            textAlign="center"
            color="inherit"
        >
            <SpaceBetween size="m">
                <b>{props.message}</b>
            </SpaceBetween>
        </Box>
    )
}