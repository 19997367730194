import React from 'react';
import 'src/assets/styles/react-table.scss';
import '../custom-tables-shared/CustomTables.scss';
import 'src/components/CustomAppLayout.scss';
import {DefaultColumnFilter} from "src/components/shared/TPEReactTable";
import {ReportSummary} from "src/models/reports/ListReportResponse";
import {TPERowSelectableReactTable} from "src/components/shared/TPERowSelectableReactTable";
import ReportStatus from "src/components/reports/ReportStatus";

export default function ReportingHistoryTableGrid(props: { loading: boolean, reportTables: ReportSummary[], onRowClicked:(...args : any[]) => any}) {
    const {loading, reportTables = []} = props;

    const columnDefinitions = React.useMemo(() => [
            {
                accessor: "reportId",
                Header: (e: any) => <div className="cell-text">Report Id</div>,
                Filter: DefaultColumnFilter,
                Cell: (e: any) => <div className="cell-text">{e.value}</div>
            },
            {
                accessor: "reportName",
                Header: (e: any) => <div className="cell-text">Report Name</div>,
                Filter: DefaultColumnFilter,
                Cell: (e: any) => <div className="cell-text">{e.value}</div>
            },
            {
                accessor: "createdAt",
                Header: (e: any) => <div className="cell-text">Created On</div>,
                Filter: DefaultColumnFilter,
                Cell: (e: any) => <div className="cell-text">{ new Date(e.value).toLocaleString()}</div>
            },
            {
                accessor: "period",
                Header: (e: any) => <div className="cell-text">Period</div>,
                Filter: DefaultColumnFilter,
                Cell: (e: any) => <div className="cell-text">{e.value}</div>
            },
            {
                accessor: "accountingDay",
                Header: (e: any) => <div className="cell-text">Accounting Day</div>,
                Filter: DefaultColumnFilter,
                Cell: (e: any) => <div className="cell-text">{e.value}</div>
            },
            {
                accessor: "reportStatus",
                Header: (e: any) => <div className="cell-text">Report Status</div>,
                Filter: DefaultColumnFilter,
                Cell: (e: any) => <div className="cell-text"> {<ReportStatus reportStatus={e.value} /> } </div>
            }
        ]
        , [reportTables]);

    return (
            <TPERowSelectableReactTable
                className={'reportHistoryGrid'}
                isSearching={loading}
                data={reportTables}
                onRowClicked = {props.onRowClicked}
                columnDefinitions={columnDefinitions}
                sortable={true}
            />
    )
}