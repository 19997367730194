import React, {useEffect, useState} from "react";
import ServiceCollection from "src/services/ServiceCollection";
import {
    Box,
    Button,
    PropertyFilterProps,
    SpaceBetween
} from "@amzn/awsui-components-react";
import {Container, Grid, Header, SelectProps} from "@amzn/awsui-components-react/polaris";
import TPTaxCwbReportFilterSelector from "src/components/reports/cwbReport/TPTaxCwbReportFilterSelector";
import {
    createGetTPReportFiltersRequestPayloadForCWBReport,
    FilterOption,
    FilterProperty,
    GetTPReportFiltersRequest
} from "src/models/reports/GetTPReportFilters";
import CONSTANTS from "src/utils/constants";
import TPPropertyFilter from "src/components/shared/TPPropertyFilter";
import {TPTaxReportMapResponse} from "src/models/reports/TPTaxReport";
import {FetchReportRequest, PaginationConfig} from "src/models/reports/FetchReport";
import {useHistory, useParams} from "react-router-dom";
import {extractWorkbookIdFromWorkbookLabel, parseCwbUIFilter} from "src/utils/ReportUtils";
import TPMultiCLIReportTableGrid from "src/components/reports/TPMultiCLIReportTableGrid";
import {DownloadTPTaxMultiCLIReportRequest} from "src/models/reports/DownloadTPTaxMultiCLIReport";
import TPMultiCLIReportCollectionPreferences from "src/components/shared/TPMultiCLIReportCollectionPreferences";
import TPMultiCLIReportPagination from "src/components/shared/TPMultiCLIReportPagination";
import {handleFetchReportResponse} from "src/components/reports/TPMultiCLIReportsShared";

export default function TPTaxCwbReportView(props: { services: ServiceCollection }) {
    const DEFAULT_PAGE_SIZE: number = 9;
    const DEFAULT_FIRST_PAGE: number = 1;
    const {services} = props;
    const history = useHistory();
    const {filter} = useParams<{ filter: string }>();
    const [currentUrlParams, setCurrentUrlParams] = useState(parseCwbUIFilter(decodeURIComponent(filter)) || '');
    const [selectedWorkbook, setSelectedWorkbook] = React.useState(null as SelectProps.Option | null);
    const [selectedAccountingPeriod, setSelectedAccountingPeriod] = React.useState(null as SelectProps.Option | null);
    const [selectedAccountingDayOption, setSelectedAccountingDayOption] = React.useState(null as SelectProps.Option | null);
    const [filtersDataRequestPayload, setFiltersDataRequestPayload] = React.useState(null as GetTPReportFiltersRequest | null);
    const [filtersData, filtersDataLoading, filtersDataError] = services.reportsService.getTPReportFilters(filtersDataRequestPayload);
    const [filterProperties, setFilterProperties] = React.useState([] as FilterProperty[]);
    const [filterOptions, setFilterOptions] = React.useState([] as FilterOption[]);
    const [fetchReportRequest, setFetchReportRequest] = React.useState(null as FetchReportRequest | null);
    const [fetchReportResponse, fetchReportLoading, fetchReportError] = services.reportsService.fetchReportData(fetchReportRequest);
    const [downloadReportRequestPayload, setDownloadReportRequestPayload] = useState(null as unknown as DownloadTPTaxMultiCLIReportRequest);
    const [downloadReportResponse, downloadTPTaxReportLoading, downloadTPTaxReportError] = services.reportsService.downloadTPTaxMultiCLIReport(downloadReportRequestPayload);
    const [reportData, setReportData] = React.useState(null as TPTaxReportMapResponse | null);
    const [pageConfig, setPageConfig] = React.useState<PaginationConfig>({
        pageNumber: DEFAULT_FIRST_PAGE,
        pageSize: DEFAULT_PAGE_SIZE
    });
    const [lastPage, setLastPage] = React.useState(DEFAULT_FIRST_PAGE);
    const [preferences, setPreferences] = React.useState({
        pageSize: DEFAULT_PAGE_SIZE
    });
    const [searchFilterQuery, setSearchFilterQuery] = React.useState<PropertyFilterProps.Query>({
        tokens: [],
        operation: "or"
    });

    useEffect(() => {
        if (!filtersDataError && !fetchReportError && !downloadTPTaxReportError) {
            return;
        }
        props.services.messageService.showErrorAutoDismissBanner(filtersDataError || fetchReportError || downloadTPTaxReportError);
    }, [filtersDataError, fetchReportError, downloadTPTaxReportError]);

    useEffect(() => {
        handleFetchReportResponse(
            fetchReportResponse,
            setReportData,
            setLastPage,
            (message) => props.services.messageService.showErrorAutoDismissBanner(message)
        );
    }, [fetchReportResponse]);

    useEffect(() => {
        if (filtersData) {
            setFilterProperties(filtersData.filterProperties);
            setFilterOptions(filtersData.filterOptions);
        }
    }, [filtersData]);

    useEffect(() => {
        if (selectedAccountingDayOption && selectedAccountingPeriod && selectedWorkbook) {
            setFetchReportRequest({
                reportName: CONSTANTS.REPORT_TYPES.TP_TAX_CWB_REPORT,
                params: {
                    accountingDay: selectedAccountingDayOption?.value as string,
                    period: selectedAccountingPeriod?.value as string,
                    workbookId: selectedWorkbook?.value as string
                },
                paginationConfig: pageConfig,
                filterConfig: {
                    propertyFilters: [...searchFilterQuery.tokens],
                    propertyFiltersOperation: searchFilterQuery.operation
                }
            });
        }
    }, [pageConfig]);

    useEffect(() => {
        if (selectedAccountingDayOption && selectedAccountingPeriod && selectedWorkbook) {
            setPageConfig({
                pageNumber: DEFAULT_FIRST_PAGE, pageSize: preferences.pageSize
            });
        }
    }, [preferences, searchFilterQuery]);

    useEffect(() => {
        if (currentUrlParams.workbookLabel
            && !(selectedAccountingDayOption && selectedAccountingPeriod && selectedWorkbook)
        ) {
            setSelectedWorkbook({
                value: extractWorkbookIdFromWorkbookLabel(currentUrlParams.workbookLabel),
                label: currentUrlParams.workbookLabel
            });
            setSelectedAccountingPeriod({value: currentUrlParams.period, label: currentUrlParams.period});
            setSelectedAccountingDayOption({
                value: currentUrlParams.accountingDay,
                label: currentUrlParams.accountingDay
            });
            setFiltersDataRequestPayload(
                createGetTPReportFiltersRequestPayloadForCWBReport(currentUrlParams.accountingDay as string,
                    currentUrlParams.period as string,
                    extractWorkbookIdFromWorkbookLabel(currentUrlParams.workbookLabel))
            );
        }

        if (selectedAccountingDayOption && selectedAccountingPeriod && selectedWorkbook) {
            setFiltersDataRequestPayload(
                createGetTPReportFiltersRequestPayloadForCWBReport(selectedAccountingDayOption.value as string,
                    selectedAccountingPeriod.value as string,
                    selectedWorkbook.value as string)
            );
        }

        setSearchFilterQuery({
            tokens: [],
            operation: "or"
        });
    }, [currentUrlParams]);

    useEffect(() => {
        if (downloadReportResponse && downloadReportResponse.reportURL) {
            window.open(downloadReportResponse.reportURL);
        }
    }, [downloadReportResponse])

    const onDownloadClicked = () => {
        setDownloadReportRequestPayload({
            reportName: CONSTANTS.REPORT_TYPES.TP_TAX_CWB_REPORT,
            params: {
                period: selectedAccountingPeriod?.value as string,
                workbookId: selectedWorkbook?.value as string,
                accountingDay: selectedAccountingDayOption?.value as string
            },
            filterConfig: (searchFilterQuery.tokens.length === 0) ? null : {
                propertyFilters: [...searchFilterQuery.tokens],
                propertyFiltersOperation: searchFilterQuery.operation
            }
        })
    }

    return (
        <SpaceBetween direction="vertical" size="l">
            <Grid gridDefinition={[{colspan: 10}, {colspan: 2}]}>
                <TPTaxCwbReportFilterSelector
                    services={services}
                    selectedWorkbook={selectedWorkbook}
                    selectedAccountingPeriod={selectedAccountingPeriod}
                    selectedAccountingDay={selectedAccountingDayOption}
                    onWorkbookSelected={(workbookOption) => setSelectedWorkbook({
                        label: workbookOption?.label,
                        value: workbookOption?.value
                    })}
                    onAccountingPeriodSelected={(accountingPeriodOption) => setSelectedAccountingPeriod({
                        label: accountingPeriodOption?.label,
                        value: accountingPeriodOption?.value
                    })}
                    onAccountingDaySelected={(accountingDayOption) => setSelectedAccountingDayOption({
                        label: accountingDayOption?.label,
                        value: accountingDayOption?.value
                    })}
                />
                <Box float="left" padding={{top: "xl"}}>
                    <Button
                        onClick={() => {
                            const newUrlParams = `${selectedWorkbook?.label}+${selectedAccountingPeriod?.value}+${selectedAccountingDayOption?.value}`;
                            setCurrentUrlParams(parseCwbUIFilter(newUrlParams));
                            history.push(`/reports/cwb/${encodeURIComponent(newUrlParams)}`);
                        }}
                        disabled={(!selectedAccountingDayOption || !selectedAccountingPeriod || !selectedWorkbook)}
                        loading={fetchReportLoading}
                        variant="primary"
                    >
                        View Report
                    </Button>
                </Box>
            </Grid>
            <Container
                header={
                    <>
                        <Header variant="h2"
                                actions={
                                    <SpaceBetween direction="horizontal" size="xs">
                                        <Button
                                            disabled={(reportData === null) || fetchReportLoading
                                                || (searchFilterQuery.tokens.length == 0)}
                                            onClick={onDownloadClicked}
                                            loading={downloadTPTaxReportLoading}>
                                            Download Filtered Report
                                        </Button>
                                        <Button
                                            disabled={(reportData === null) || fetchReportLoading}
                                            onClick={onDownloadClicked}
                                            loading={downloadTPTaxReportLoading}>
                                            Download Report
                                        </Button>
                                    </SpaceBetween>
                                }>
                            Report Details
                        </Header>
                        <Grid
                            gridDefinition={[{colspan: 9}, {colspan: 3}]}
                        >
                            <TPPropertyFilter
                                filterProperties={filterProperties}
                                filterOptions={filterOptions}
                                filtersDataLoading={filtersDataLoading}
                                query={searchFilterQuery}
                                setQuery={setSearchFilterQuery}
                            />
                            <Box float={"right"}>
                                <SpaceBetween direction="horizontal" size="xs">
                                    <TPMultiCLIReportPagination
                                        currentPageIndex={pageConfig.pageNumber}
                                        pagesCount={lastPage}
                                        pageSize={pageConfig.pageSize}
                                        disabled={fetchReportResponse === null || fetchReportLoading}
                                        setPageConfig={setPageConfig}
                                    />

                                    <TPMultiCLIReportCollectionPreferences
                                        fetchReportResponse={fetchReportResponse}
                                        fetchReportLoading={fetchReportLoading}
                                        setPreferences={setPreferences}
                                        preferences={preferences}
                                        options={[3,6,9]}
                                    />
                                </SpaceBetween>
                            </Box>
                        </Grid>
                    </>
                }
            >
                {
                    reportData && <TPMultiCLIReportTableGrid
                        reportData={reportData}
                        services={services}
                        fetchReportLoading={fetchReportLoading}
                        reportMetaData={{
                            reportName: CONSTANTS.REPORT_TYPES.TP_TAX_CWB_REPORT,
                            period: selectedAccountingPeriod?.value as string,
                            accountingDay: selectedAccountingDayOption?.value as string,
                            workbookLabel: selectedWorkbook?.label as string
                        }}
                    />
                }
            </Container>
        </SpaceBetween>
    );
}
