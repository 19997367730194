import { DbSortOrder } from "src/models/common/TPESearchRequest";
import DASHBOARD_CONSTANTS from "./dashboardConstants";
import { OpenApprovalsSearchRequest } from "src/models/dashboard/OpenApproval";

export function getTopOpenApprovalsSearchPayload(): OpenApprovalsSearchRequest {
    return {
        page: 1,
        pageSize: DASHBOARD_CONSTANTS.TOP_N,
        sortField: DASHBOARD_CONSTANTS.OPEN_APPROVAL_FIELDS.DUE_DATE.DB_FIELD,
        sortOrder: DbSortOrder.ASCENDING
    };
}