import React, { useEffect, useContext, useState } from 'react';
import { SpaceBetween } from '@amzn/awsui-components-react/polaris';
import { initialState } from 'src/models/dashboard/DashboardState';
import { dashboardReducer, ACTIONS } from 'src/services/dashboard/DashboardReducer';
import './Dashboard.scss';
import { GlobalAppContext } from 'src/components/App';
import useReducerWithLogger from 'src/services/utils/ReducerWithLogger';
import DashboardTiles from './DashboardTiles';
import { getTopOpenCalcReviewsSearchPayload } from './OpenCalculationReviewsSearchHelper';
import OpenApprovalsAndRequestsContainer from './OpenApprovalsAndRequestsContainer';
import { getTopOpenApprovalsSearchPayload } from './OpenApprovalsSearchHelper';
import { getTopOpenRequestsSearchPayload } from './OpenRequestsSearchHelper';
import OpenCalculationReviewsContainer from './OpenCalculationReviewsContainer';
import ActionItemsSummary from './ActionItemsSummary';
import TPEErrorWatcher from '../shared/TPEErrorWatcher';


export default function DashboardView() {
    const [state, dispatch] = useReducerWithLogger(dashboardReducer, initialState);
    const { userProfile, services } = useContext(GlobalAppContext);

    const { searchOpenCalcReviewsPayload, searchOpenApprovalsPayload, searchOpenRequestsPayload } = state;

    const [ loadTime, setLoadTime ] = useState('');

    const [openCalculationReviewsResult, openCalculationReviewsLoading, searchOpenCalcReviewsError] = services.dashboardService.searchOpenCalculationReviews(searchOpenCalcReviewsPayload);
    const [openApprovalsResult, openApprovalsLoading, searchOpenApprovalsError] = services.dashboardService.searchOpenApprovals(searchOpenApprovalsPayload);
    const [openRequestsResult, openRequestsLoading, searchOpenRequestsError] = services.dashboardService.searchOpenRequests(searchOpenRequestsPayload);
    const [dashboardSummaryResult, dashboardSummaryLoading, dashboardSummaryError] = services.dashboardService.getActionItemsSummary(loadTime);

    useEffect(() => {
        setLoadTime(new Date().toString());
        dispatch(ACTIONS.SET_OPEN_CALC_REVIEWS_PAYLOAD.withPayload(getTopOpenCalcReviewsSearchPayload()));
        dispatch(ACTIONS.SET_OPEN_APPROVALS_PAYLOAD.withPayload(getTopOpenApprovalsSearchPayload()));
        dispatch(ACTIONS.SET_OPEN_REQUESTS_PAYLOAD.withPayload(getTopOpenRequestsSearchPayload()));
    }, [])

    return (
        <div style={{ marginLeft: '50px', marginRight:'50px' }}>
            <div className="welcomeUser">Welcome {userProfile.firstName}!</div>
            <SpaceBetween direction="vertical" size="l">
                <ActionItemsSummary {... {dashboardSummaryResult, dashboardSummaryLoading}}/>
                <OpenApprovalsAndRequestsContainer {... {openApprovalsResult, openApprovalsLoading, openRequestsResult, openRequestsLoading}}/>
                <OpenCalculationReviewsContainer {... {openCalculationReviewsResult, openCalculationReviewsLoading}} />
                <DashboardTiles />
            </SpaceBetween>
            <TPEErrorWatcher services={services} errors={[searchOpenCalcReviewsError, searchOpenApprovalsError, searchOpenRequestsError, dashboardSummaryError]} />
        </div>
    );
}