import { te } from "date-fns/locale";
import {useEffect, useState} from "react";
import CalculationStep from "src/models/calculation-builder/CalculationStep";
import { CalculationTemplateMetaData, CalculationTemplate } from "src/models/calculation-builder/CalculationTemplate";
import LinkedCLI from "src/models/calculation-builder/LinkedCLI";
import LinkToTemplate from "src/models/calculation-builder/LinkToTemplate";

import apiService from "src/services/ApiCallService";
import ArrayUtils from "src/utils/arrayUtils";
import ErrorUtils from "src/utils/ErrorUtils";
import StringUtils from "src/utils/stringUtils";
import {BulkLinkToTemplate} from "src/models/calculation-builder/BulkLinkToTemplate";
import {BulkCloneCalculation} from "src/models/calculation-builder/BulkCloneCalculation";
import { CalculationExecution } from "src/models/calculation-builder/CalculationExecution";

/**
 * This class should only contain the API calls related to CalculationsBuilder
 */
export default class CalculationBuilderService {

    createRenameCalculationTemplate(calculationTemplate?: CalculationTemplateMetaData): [CalculationTemplateMetaData, boolean, string] {
        const [result, setResult] = useState(null as unknown as CalculationTemplateMetaData);
        const [loading, setLoading] = useState(false);
        const [error, setError] = useState('');

        useEffect(() => {
            async function postData(template: CalculationTemplateMetaData) {
                try {
                    setLoading(true);
                    setError('');
                    const response = await (
                        template.templateId == null? 
                            apiService.createCalculationTemplate(template) :
                            apiService.renameCalculationTemplate(template.templateId || 'null',template)
                    )
                    const json = (await response.json());
                    setResult(json as CalculationTemplateMetaData);
                }
                catch (ex: any) {
                    setError(ErrorUtils.getMessage(ex));
                }
                finally {
                    setLoading(false);
                }
            }
            if (calculationTemplate != null) {
                postData(calculationTemplate);
            }
        }, [calculationTemplate]);
        return [result, loading, error]
    }

    getLinkedCLIs(templateId?:string): [LinkedCLI[], boolean, string] {
        const [result, setResult] = useState([]);
        const [loading, setLoading] = useState(false);
        const [error, setError] = useState('');

        useEffect(() => {
            async function fetchData(templateId:string) {
                try {
                    setLoading(true);
                    const response = await apiService.getLinkedCLIs(templateId);
                    const json = await response.json();
                    setResult(json.calculations);
                }
                catch (ex) {       
                    setError(ErrorUtils.getMessage(ex));
                }
                finally {
                    setLoading(false);
                }
            }
            if (templateId != null) {
                fetchData(templateId);
            }
        }, [templateId]);

        return [result, loading, error];
    }

    unlinkCalculationFromTemplate(payload?: CalculationTemplate): [CalculationTemplate | null, boolean, string] {
        const [result, setResult] = useState(null as CalculationTemplate | null);
        const [loading, setLoading] = useState(false);
        const [error, setError] = useState('');

        useEffect(() => {
            async function postData(data:CalculationTemplate) {
                try {
                    setLoading(true);
                    setError('');
                    const response = await apiService.unlinkCalculationFromTemplate(data.calculationNumber, data.templateId);
                    const json = await response.json();
                    setResult(json);
                }
                catch (ex:any) {
                    setError(ErrorUtils.getMessage(ex));
                }
                finally {
                    setLoading(false);
                }
            }
            if (payload != null) {
                postData(payload);
            }
        }, [payload]);
        return [result, loading, error]
    }

    linkCalculationToTemplate(payload?: LinkToTemplate): [LinkToTemplate | null, boolean, string] {
        const [result, setResult] = useState(null as LinkToTemplate | null);
        const [loading, setLoading] = useState(false);
        const [error, setError] = useState('');

        useEffect(() => {
            async function postData($this: CalculationBuilderService, data: LinkToTemplate) {
                try {
                    setLoading(true);
                    setError('');
                    const response = await apiService.linkCalculationToTemplate(data.calculationNumber, data.templateId, data);
                    const json = await response.json();
                    setResult(json);
                }
                catch (ex:any) {
                    setError(ErrorUtils.getMessage(ex));
                }
                finally {
                    setLoading(false);
                }
            }
            if (payload != null) {
                postData(this, payload);
            }
        }, [payload]);
        return [result, loading, error]
    }

    bulkLinkCalculationsToTemplate(payload?: BulkLinkToTemplate): [BulkLinkToTemplate | null, boolean, string] {
        const [result, setResult] = useState(null as BulkLinkToTemplate | null);
        const [loading, setLoading] = useState(false);
        const [error, setError] = useState('');

        useEffect(() => {
            async function postData($this: CalculationBuilderService, data: BulkLinkToTemplate) {
                try {
                    setLoading(true);
                    setError('');
                    const response = await apiService.bulkLinkCalculationsToTemplate(data.templateId, data);
                    const json = await response.json();
                    setResult(json);
                }
                catch (ex:any) {
                    setError(ErrorUtils.getMessage(ex));
                }
                finally {
                    setLoading(false);
                }
            }
            if (payload != null) {
                postData(this, payload);
            }
        }, [payload]);
        return [result, loading, error]
    }

    cloneCalculation(query: string, calculationNumber: string, templateId: string): [string, boolean, string] {
        const [result, setResult] = useState(null as unknown as string);
        const [loading, setLoading] = useState(false);
        const [error, setError] = useState('');

        useEffect(() => {
            async function postData(query: string, calculationNumber: string, templateId: string) {
                try {
                    setLoading(true);
                    setError('');
                    const response = await apiService.cloneCalculation(calculationNumber, templateId);
                    const json = await response.json();
                    setResult(json.calculationNumber + query);
                }
                catch (ex:any) {
                    setError(ErrorUtils.getMessage(ex));
                }
                finally {
                    setLoading(false);
                }
            }
            if (!StringUtils.isNullOrEmpty(query) && !StringUtils.isNullOrEmpty(calculationNumber) && !StringUtils.isNullOrEmpty(templateId)) {
                postData(query, calculationNumber, templateId);
            }
        }, [query]);
        return [result, loading, error]
    }

    bulkCloneCalculation(payload?: BulkCloneCalculation): [BulkCloneCalculation | null, boolean, string] {
        const [result, setResult] = useState(null as BulkCloneCalculation | null);
        const [loading, setLoading] = useState(false);
        const [error, setError] = useState('');

        useEffect(() => {
            async function postData($this: CalculationBuilderService, data: BulkCloneCalculation) {
                try {
                    setLoading(true);
                    setError('');
                    const response = await apiService.bulkCloneCalculation(data.templateId, payload);
                    const json = await response.json();
                    setResult(json);
                }
                catch (ex:any) {
                    setError(ErrorUtils.getMessage(ex));
                }
                finally {
                    setLoading(false);
                }
            }
            if (payload != null) {
                postData(this, payload);
            }
        }, [payload]);
        return [result, loading, error]
    }

    getTemplates(templatesNeedRefresh: boolean, workbookId?: string | undefined): [CalculationTemplate[], boolean, string] {
        const [result, setResult] = useState([] as CalculationTemplate[]);
        const [loading, setLoading] = useState(false);
        const [error, setError] = useState('');

        useEffect(() => {
            async function fetchData(workbookId: string | undefined) {
                try {
                    setLoading(true);
                    const response = await apiService.getTemplates(workbookId);
                    const json = await response.json();
                    setResult(json.templates);
                }
                catch (ex) {
                    setError(ErrorUtils.getMessage(ex));
                }
                finally {
                    setLoading(false);
                }
            }
            if (templatesNeedRefresh) {
                fetchData(workbookId);
            }
        }, [templatesNeedRefresh]);

        return [result, loading, error];
    }

    editTemplate(shouldEdit: boolean, templateId: string, calculationNumber: string): [string, boolean, string] {
        const [result, setResult] = useState(null as unknown as string);
        const [loading, setLoading] = useState(false);
        const [error, setError] = useState('');
 
        useEffect(() => {
            async function postData(templateId: string, calculationNumber: string) {
                try {
                    setLoading(true);
                    const response = await apiService.editTemplate(templateId, calculationNumber);
                    const json = await response.json();
                    setResult(json);
                }
                catch (ex) {
                    setError(ErrorUtils.getMessage(ex));
                }
                finally {
                    setLoading(false);
                }
            }
            if (shouldEdit) {
                postData(templateId, calculationNumber);
            }
        }, [shouldEdit]);
 
        return [result, loading, error];
    }
 
    saveTemplate(shouldSave: boolean, templateId: string): [any, boolean, string] {
        const [result, setResult] = useState(null as unknown as any);
        const [loading, setLoading] = useState(false);
        const [error, setError] = useState('');
 
        useEffect(() => {
            async function postData(templateId: string) {
                try {
                    setLoading(true);
                    const response = await apiService.saveTemplate(templateId);
                    const json = await response.json();
                    setResult(json);
                }
                catch (ex) {
                    setError(ErrorUtils.getMessage(ex));
                }
                finally {
                    setLoading(false);
                }
            }
            if (shouldSave) {
                postData(templateId);
            }
        }, [shouldSave]);
 
        return [result, loading, error];
    }
 
    cancelTemplateChanges(shouldCancel: boolean, templateId: string): [string, boolean, string] {
        const [result, setResult] = useState(null as unknown as string);
        const [loading, setLoading] = useState(false);
        const [error, setError] = useState('');
 
        useEffect(() => {
            async function postData(templateId: string) {
                try {
                    setLoading(true);
                    const response = await apiService.cancelTemplateChanges(templateId);
                    const json = await response.json();
                    setResult(json);
                }
                catch (ex) {
                    setError(ErrorUtils.getMessage(ex));
                }
                finally {
                    setLoading(false);
                }
            }
            if (shouldCancel) {
                postData(templateId);
            }
        }, [shouldCancel]);
 
        return [result, loading, error];
    }

    getCalculationExecution(atpCalculationId: string, period?: string) : [CalculationExecution, boolean, string] {
        const [result, setResult] = useState(null as unknown as CalculationExecution);
        const [loading, setLoading] = useState(false);
        const [error, setError] = useState('');

        useEffect(() => {
            async function fetchData(atpCalculationId: string, period: string) {
                try {
                    setLoading(true);
                    const response = await apiService.getCalculationExecution(atpCalculationId, period);
                    const json = await response.json();
                    setResult(json);
                }
                catch (ex) {
                    setError(ErrorUtils.getMessage(ex));
                }
                finally {
                    setLoading(false);
                }
            }
            if (atpCalculationId != null && period != null) {
                fetchData(atpCalculationId, period);
            }
        }, [atpCalculationId, period]);
        
        return [result, loading, error];
    }

    discardDraft(query: string, calculationNumber: string): [any, boolean, string] {
        const [result, setResult] = useState(null);
        const [loading, setLoading] = useState(false);
        const [error, setError] = useState('');

        useEffect(() => {
            async function postData(calculationNumber: string) {
                try {
                    setLoading(true);
                    const response = await apiService.discardDraft(calculationNumber);
                    const json = await response.json();
                    setResult(json);
                }
                catch (ex) {
                    setError(ErrorUtils.getMessage(ex));
                }
                finally {
                    setLoading(false);
                }
            }
            
            if (!StringUtils.isNullOrEmpty(query) && !StringUtils.isNullOrEmpty(calculationNumber)) {
                postData(calculationNumber);
            }
        }, [query]);
        
        return [result, loading, error];
    }
    
    setCalculationNumberOnSteps(steps: CalculationStep[], calculationNumber: string) {
        if (ArrayUtils.isNullOrEmpty(steps)) {
            return steps;
        }
        steps.forEach(x => x.calculationNumber = calculationNumber);
    }
}