import { Box, Container, Header, Link, Tabs } from '@amzn/awsui-components-react';
import React from 'react';
import OpenApprovalsGrid from './OpenApprovalsGrid';
import { OpenApprovalsResult } from 'src/models/dashboard/OpenApproval';
import OpenRequestsGrid from './OpenRequestsGrid';

export default function OpenApprovalsAndRequestsContainer(props: {
    openApprovalsResult: OpenApprovalsResult,
    openApprovalsLoading: boolean,
    openRequestsResult: any,
    openRequestsLoading: boolean
}) {
    const { openApprovalsResult, openApprovalsLoading, openRequestsResult, openRequestsLoading } = props;

    const getTabLabel = (tabType: string, loading: boolean, totalSize: number) => {
        const counter = loading ? '(...)' : `(${totalSize || '0'})`
        return `Open ${tabType} ${counter}`;
    }

    const getTotalCount = () => {
        if (openApprovalsLoading || openRequestsLoading) {
            return '...';
        }
        return (openApprovalsResult ? openApprovalsResult.totalSize : 0) + (openRequestsResult ? openRequestsResult.totalSize : 0);
    }

    return <Container
      header={
        <Header
          counter={`(${getTotalCount()})`}
          description="Assigned and relevant to me"
        >
          Open approvals and requests
        </Header>
      }
      footer={
        <Box textAlign="center">
            <Link href="#">View all approvals and requests</Link>
        </Box>
      }
    >
      <Tabs
      disableContentPaddings={true}
      tabs={[
        {
          label: getTabLabel('approvals', openApprovalsLoading, openApprovalsResult?.totalSize),
          id: "openApprovalsTab",
          content: <OpenApprovalsGrid openApprovalsResult={openApprovalsResult} isLoading={openApprovalsLoading} dashboardView={true} />
        },
        {
          label: getTabLabel('requests', openRequestsLoading, openRequestsResult?.totalSize),
          id: "openRequestsTab",
          content: <OpenRequestsGrid openRequestsResult={openRequestsResult} isLoading={openRequestsLoading} dashboardView={true} />
        }
      ]}
    />
    </Container>
}