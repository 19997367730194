import React from "react";
import {Button, CollectionPreferencesProps} from "@amzn/awsui-components-react";
import {
    COLUMNS_FOR_ACTIVITY_LOG_TABLE, NO_DATA_MESSAGE, NO_MATCH_MESSAGE, TEST_DATA_FOR_ACTIVITY_LOG
} from "src/components/ic-account-mapping/constants";
import {
    PropertyFilterOperator,
    PropertyFilterOperatorExtended,
    PropertyFilterProperty,
    useCollection
} from "@amzn/awsui-collection-hooks";
import EmptyTableView from "src/components/ic-account-mapping/shared/EmptyTableView";
import {camelCaseToSentenceCase, formatDateTime} from "src/components/ic-account-mapping/Utils";
import BaseTableView from "src/components/ic-account-mapping/shared/BaseTableView";
import {TableProps} from "@amzn/awsui-components-react/polaris/table";
import {DateTimeForm} from "src/components/ic-account-mapping/shared/DateTimeForm";

function prepareColumnDefinition(): TableProps.ColumnDefinition<any>[] {
    const columnDefinitions: TableProps.ColumnDefinition<any>[] = [];
    Object.values(COLUMNS_FOR_ACTIVITY_LOG_TABLE).forEach((columnId: string) => {
        columnDefinitions.push({
            id: columnId,
            header: camelCaseToSentenceCase(columnId),
            cell: values => {
                if (columnId === COLUMNS_FOR_ACTIVITY_LOG_TABLE.TIMESTAMP) {
                    return values[columnId] ? values[columnId].toISOString() : "";
                } else {
                    return values[columnId];
                }
            },
            isRowHeader: true,
            sortingField: columnId,
        });
    });
    return columnDefinitions;
}

// These custom operators are based on UX requirements
function getFilteringOperators(columnName: string): (PropertyFilterOperator | PropertyFilterOperatorExtended<any>)[] {
    switch (columnName) {
        case COLUMNS_FOR_ACTIVITY_LOG_TABLE.ACCOUNT_ID:
        case COLUMNS_FOR_ACTIVITY_LOG_TABLE.ITEM:
            return ["=", ":"];
        case COLUMNS_FOR_ACTIVITY_LOG_TABLE.PERFORMED_BY:
            return ["="];
        case COLUMNS_FOR_ACTIVITY_LOG_TABLE.TIMESTAMP:
            return [">=", "<="].map((operator) => {
                return {
                    operator: operator,
                    match: "datetime",
                    form: DateTimeForm,
                    format: formatDateTime,
                }
            });
        default:
            return ["=", "!="];
    }
}

export default function ActivityLogSectionView() {
    const [preferences, setPreferences] = React.useState<CollectionPreferencesProps.Preferences>({
        // TODO: Keeping it 1 for now, as test data contains only 5 records. It will be updated to 10, after backend is integrated
        pageSize: 1,
    });

    const useCollectionResult = useCollection(
        TEST_DATA_FOR_ACTIVITY_LOG,
        {
            pagination: {
                pageSize: preferences.pageSize
            },
            propertyFiltering: {
                empty: <EmptyTableView message={NO_DATA_MESSAGE}/>,
                noMatch: <EmptyTableView message={NO_MATCH_MESSAGE}/>,
                defaultQuery: {
                    tokens: [],
                    operation: "or"
                },
                filteringProperties: (Object.values(COLUMNS_FOR_ACTIVITY_LOG_TABLE).map((columnName: any): PropertyFilterProperty => ({
                    key: columnName,
                    propertyLabel: camelCaseToSentenceCase(columnName),
                    groupValuesLabel: `${camelCaseToSentenceCase(columnName)} Values`,
                    operators: getFilteringOperators(columnName),
                    // Defining defaultOperator is required to remove "=" from operators list for timestamp column
                    defaultOperator: columnName === COLUMNS_FOR_ACTIVITY_LOG_TABLE.TIMESTAMP ? "<=" : "="
                })))
            }
        }
    )

    return (
        <BaseTableView
            header={"Activity Log"}
            description={"Find history of change on account mapping"}
            headerActionButtons={[
                // TODO: Add download functionality once download API is available
                <Button> Download </Button>
            ]}
            columnDefinitions={prepareColumnDefinition()}
            preferences={preferences}
            setPreferences={setPreferences}
            useCollectionResult={useCollectionResult}
        />
    );
}