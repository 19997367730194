import React from 'react';
import Table, {TableProps} from "@amzn/awsui-components-react/polaris/table";
import {CollectionPreferences, CollectionPreferencesProps, Header, SpaceBetween} from "@amzn/awsui-components-react";
import {UseCollectionResult} from "@amzn/awsui-collection-hooks";
import Pagination from "@amzn/awsui-components-react/polaris/pagination";
import PropertyFilter from "@amzn/awsui-components-react/polaris/property-filter";
import {PROPERTY_FILTERING_I18N_CONSTANTS} from "src/components/shared/TPPolarisTable";
import {InlineEditingHandlerAttributes} from "src/components/ic-account-mapping/constants";

interface BaseTableViewProps {
    header: string,
    description?: string,
    headerActionButtons?: React.ReactNode[]
    columnDefinitions: TableProps.ColumnDefinition<any>[],
    preferences: CollectionPreferencesProps.Preferences,
    setPreferences: React.Dispatch<React.SetStateAction<CollectionPreferencesProps.Preferences>>,
    useCollectionResult: UseCollectionResult<any>,
    setInlineEditingHandlerAttributes?: React.Dispatch<React.SetStateAction<InlineEditingHandlerAttributes>>,
}

export default function BaseTableView(props: BaseTableViewProps) {
    const {
        items,
        filteredItemsCount,
        collectionProps,
        propertyFilterProps,
        paginationProps
    } = props.useCollectionResult;

    return (
        <Table
            header={
                <Header
                    counter={`(${filteredItemsCount})`}
                    description={props.description}
                    actions={<SpaceBetween size={"xs"} direction={"horizontal"}>
                        {props.headerActionButtons?.map((actionButton) => {
                            return actionButton;
                        })} </SpaceBetween>}
                >
                    {props.header}
                </Header>
            }

            items={items}
            columnDefinitions={props.columnDefinitions}

            loadingText="Loading resources"
            stickyHeader

            ariaLabels={{
                activateEditLabel: (column, item) =>
                    `Edit ${column.header}`,
                cancelEditLabel: column =>
                    `Cancel editing ${column.header}`,
                submitEditLabel: column =>
                    `Submit editing ${column.header}`,
                tableLabel: "Table with inline editing"
            }}

            preferences={<CollectionPreferences
                onConfirm={({detail}) => props.setPreferences({
                    pageSize: detail.pageSize ? detail.pageSize : 0
                })}
                preferences={props.preferences}
                title="Preferences"
                confirmLabel="Confirm"
                cancelLabel="Cancel"
                pageSizePreference={{
                    title: "Page size",
                    options: [
                        {value: 10, label: "10 Items"},
                        {value: 20, label: "20 Items"},
                        {value: 30, label: "30 Items"}
                    ]
                }}
            />}

            {...collectionProps}

            pagination={
                <Pagination {...paginationProps}/>
            }

            filter={
                <PropertyFilter
                    {...propertyFilterProps}
                    expandToViewport
                    filteringPlaceholder="Search or Filter"
                    i18nStrings={PROPERTY_FILTERING_I18N_CONSTANTS}
                />
            }

            submitEdit={(
                item: any,
                column: TableProps.ColumnDefinition<any>,
                newValue: any
            ) => {
                props.setInlineEditingHandlerAttributes?.({
                    item: item,
                    column: column,
                    newValue: newValue
                });
            }}
        />
    )
}