import React, { useState } from 'react';
import TPENavigator from '../TPENavigator';
import { Container, Box, Button, Link } from '@amzn/awsui-components-react';
import CONSTANTS from 'src/utils/constants';

interface DashboardTileProps {
    imageSrc: string,
    imageAlt: string,
    imageClass: string,
    user: string,
    header: string,
    description: string,
    buttonText: string,
    navigateTo: string,
}

const dashboardTilesProps: DashboardTileProps[] = [
    {
        imageSrc: 'dashboard-calculation.svg',
        imageAlt: 'Calculation Management',
        imageClass: 'intercompanyCalculationManagementImage',
        user: 'Accountant',
        header: 'Intercompany Calculation Management',
        description: 'Unlock seamless transfer pricing and intercompany calculation capabilities with ATP',
        buttonText: 'Go to TP Worklists',
        navigateTo: CONSTANTS.PAGE_NAV.AGREEMENTS.URL
    },
    {
        imageSrc: 'dashboard-reporting.svg',
        imageAlt: 'ATP Reporting',
        imageClass: 'atpReportingImage',
        user: 'Tax Analyst',
        header: 'ATP Reporting',
        description: 'Gain comprehensive visibility into transfer pricing and intercompany transactions with ATP reporting',
        buttonText: 'Go to ATP Reports',
        navigateTo: CONSTANTS.PAGE_NAV.REPORT_DASHBOARD.URL
    }
]

export default function DashboardTiles() {
    const [ navigateURL, setNavigateURL ] = useState('');
    return (
        <div className="dashboardTilesContainer">
            <TPENavigator path={navigateURL}/>
            {dashboardTilesProps.map(p => 
                <div className="dashboardTile">
                <Container
                    media={{
                        content: (
                            <img
                                src={CONSTANTS.IMAGE_PATH + p.imageSrc}
                                alt={p.imageAlt}
                                className={p.imageClass}
                            />
                        ),
                        position: "side",
                        width: "53%"
                    }}>
                        <Box variant="small">
                            {p.user}
                        </Box>
                        <Box variant="h2" data-class="dashboardTileTitle">
                            <Box variant="p" fontSize="heading-m">
                                {p.header}
                            </Box>
                        </Box>
                        <Box variant="p">
                            {p.description}
                        </Box>
                    <Link onFollow={() => setNavigateURL(p.navigateTo)}>
                        <Button data-class="dashboardTileActionButton">
                            {p.buttonText}
                        </Button>
                    </Link>
                </Container>
            </div>
            )}
        </div>
    )
}
