import React, { useEffect, useState } from 'react';
import ServiceCollection from 'src/services/ServiceCollection';
import ATPHorizontalRadioGroup from '../shared/ATPHorizontalRadioGroup';
import { Button, Checkbox, FormField, SpaceBetween, StatusIndicator, Textarea } from '@amzn/awsui-components-react';
import { ACTION_TYPE, TPEBasicModal } from '../shared/TPEBasicModal';
import CONSTANTS from 'src/utils/constants';
import { GlobalAppContext } from '../App';
import WebSocketApi from 'src/services/WebSocketApi';

export default function BulkActionsView(props: {services: ServiceCollection}) {
    const { services } = props;
    const { userProfile } = React.useContext(GlobalAppContext);
    
    const bulkActionOptions = [
        { value: 'SUBMIT', label: 'Submit calculations' },
        { value: 'APPROVE', label: 'Approve calculations' },
        { value: 'VALIDATE', label: 'Validate group calculations'},
        { value: 'CREATE_DRAFT', label: 'Create draft calculations'}
    ];

    const [selectedAction, setSelectedAction] = useState(bulkActionOptions[0].value);
    const [calculations, setCalculations] = useState(null as any as string);
    const [checked, setChecked] = useState(false);
    const [showConfirmation, setShowConfirmation] = useState(false);
    const [result, setResult] = useState(null as unknown as any);
    const [progressUpdate, setProgressUpdate] = useState(null as unknown as string);
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        const webSocketListener = setupWebSocketEvents(services, setProgressUpdate, setResult, setLoading);
        return () => {
            // Anything in here is fired on component unmount.
            services.atpWebSocketApiService.removeListener('',webSocketListener);
        }
    }, [])

    const process = () => {
        if (calculations == null || calculations.trim() == '') {
            if (selectedAction == 'VALIDATE') {
                services.messageService.showErrorBanner('A group number is required.');
            } else {
                services.messageService.showErrorBanner('At least one calculation number is required.');
            }
            return;
        }
        if (selectedAction == 'VALIDATE') {
            submitFormForValidation();
        } else {
            // Trim and remove duplicates
            const calcArr = calculations.split(',').filter(x => x.trim() != '').map(x => x.trim()).filter((value, index, self) => index == self.indexOf(value));
            setCalculations(calcArr.join(', '));
            if (calcArr.length == 0) {
                services.messageService.showErrorBanner('At least one calculation number is required.');
                return;
            }
            if (selectedAction == 'CREATE_DRAFT') {
                submitForm();
                return;
            }
            setShowConfirmation(true);
        }
    }

    const submitForm = () => {
        const calcArr = calculations.split(', ');
        const payload = {
            calculationNumbers: calcArr,
            bulkAction: selectedAction,
            onlyAllowedCalculations: checked,
            requestUser: userProfile.user
        }
        setLoading(true);
        setResult(null as unknown as any);
        setProgressUpdate(null as unknown as string);
        services.atpWebSocketApiService.performBulkAction(payload);
        services.messageService.showInfoAutoDismissBanner(`Processing ${calcArr.length} calculations.`);
        setShowConfirmation(false);
    }

    const submitFormForValidation = () => {
        const payload = {
            groupNumber: calculations.trim(),
            bulkAction: selectedAction,
            requestUser: userProfile.user
        }
        setLoading(true);
        setResult(null as unknown as any);
        setProgressUpdate(null as unknown as string);
        services.atpWebSocketApiService.performBulkAction(payload);
        services.messageService.showInfoAutoDismissBanner(`Processing group ${calculations.trim()}.`);
        setShowConfirmation(false);
    }

    const clear = () => {
        setCalculations(null as unknown as string);
        setResult(null as unknown as any);
    }

    return <div className="bulkActionContainer">
        <SpaceBetween direction="horizontal" size="m">
            <SpaceBetween direction="vertical" size="xl">
                <FormField label="Select bulk action">
                    <ATPHorizontalRadioGroup
                        data-class="basicModalRadioGroup"
                        onChange={setSelectedAction}
                        value={selectedAction}
                        items={bulkActionOptions}
                    />
                </FormField>
                <FormField data-class="bulkActionCheckbox">
                    <Checkbox onChange={({ detail }) => setChecked(detail.checked)} checked={checked} disabled={['VALIDATE', 'CREATE_DRAFT'].includes(selectedAction)}>
                        {selectedAction == 'APPROVE' ? 'Only process calculations for which I am the workbook reviewer.'
                        : 'Only process calculations for which I am the calc assignee or builder.'} (Applicable only for APTTUS CLIs)
                    </Checkbox>
                </FormField>
                <div className="bulkActionBar">
                    <SpaceBetween direction="horizontal" size="m">
                        <Button variant="primary" disabled={calculations == null || calculations.trim() == '' || loading} onClick={process}>
                            {loading ? <StatusIndicator type="loading">Processing</StatusIndicator>  : 'Process' }
                        </Button>
                        <Button onClick={clear} disabled={loading}>Clear</Button>
                    </SpaceBetween>
                </div>
            </SpaceBetween>
            <FormField data-class="calculationsTextArea" label={selectedAction == 'VALIDATE' ? 'Enter group number' : 'Enter calculation numbers'}>
                <Textarea
                    onChange={({ detail }) => setCalculations(detail.value)}
                    value={calculations}
                    placeholder={selectedAction == 'VALIDATE' ? 'Enter a group number' : 'Enter calculation numbers separated by commas'}
                    rows={7}
                    />
            </FormField>
        </SpaceBetween>
        { ['SUBMIT', 'APPROVE'].includes(selectedAction) && (progressUpdate != null || result != null) && 
            <div className="resultsDiv">
                <div className="innerResultsDiv">
                {progressUpdate != null && <span>{progressUpdate}</span>}
                {result != null &&
                    <React.Fragment>
                        <span><b>{result.numSuccessfulCalculations}</b> calculations successfully processed.</span>
                        {result.errors != null && result.errors.size > 0 &&
                            <React.Fragment>
                                <br /><br />
                                The following calculations were not successfully processed.
                                <br/><br/>
                                <ul>
                                    {Array.from((result.errors as Map<string, string>).keys()).map((k: string) => <li><b>{k}: </b>{result.errors.get(k) || ''}</li>)}
                                </ul>
                            </React.Fragment>
                        }
                    </React.Fragment>
                }
                </div>
            </div>
        }
        { selectedAction == 'CREATE_DRAFT' && (progressUpdate != null || result != null) && 
            <div className="resultsDiv">
                <div className="innerResultsDiv">
                {progressUpdate != null && <span>{progressUpdate}</span>}
                {result != null &&
                    <React.Fragment>
                        <span><b>{result.activeCalcs[0]}</b> active calculations moved to draft.</span>
                        <br/>
                        <span><b>{result.pendingCalcs[0]}</b> pending calculations moved to draft.</span>
                        {result.errors != null && result.errors.size > 0 &&
                            <React.Fragment>
                                <br /><br />
                                The following calculations were not processed.
                                <br/><br/>
                                <ul>
                                    {Array.from((result.errors as Map<string, string>).keys()).map((k: string) => <li><b>{k}: </b>{result.errors.get(k) || ''}</li>)}
                                </ul>
                            </React.Fragment>
                        }
                    </React.Fragment>
                }
                </div>
            </div>
        }
        { selectedAction == 'VALIDATE' && (progressUpdate != null || result != null) && 
            <div className="resultsDiv">
                <div className="innerResultsDiv">
                {progressUpdate != null && <span>{progressUpdate}</span>}
                {result != null &&
                    <React.Fragment>
                        <span>This group contains <b>{result.activeCalcs.length}</b> active calculations and <b>{result.draftCalcs.length}</b> non-active calculations.</span>
                        {result.draftCalcs.length > 0 &&
                            <React.Fragment>
                                <br /><br />
                                <b><u>Non-active calculations</u></b>
                                <br/><br/>
                                <ul>
                                    {result.draftCalcs.map((c: string) => <li>{c}</li>)}
                                </ul>
                            </React.Fragment>
                        }
                        {result.activeCalcs.length > 0 &&
                            <React.Fragment>
                                <br /><br />
                                <b><u>Active calculations</u></b>
                                <br/><br/>
                                <ul>
                                    {result.activeCalcs.map((c: string) => <li>{c}</li>)}
                                </ul>
                            </React.Fragment>
                        }
                    </React.Fragment>
                }
                </div>
            </div>
        }
        <TPEBasicModal
            title={selectedAction == bulkActionOptions[0].value ? 'Bulk submit confirmation' : 'Bulk approve confirmation'}
            action={ACTION_TYPE.CONFIRM_CANCEL}
            visible={showConfirmation}
            onConfirm={submitForm}
            onCancel={() => setShowConfirmation(false)}
        >
            By clicking <b>Confirm</b> you agree that you have reviewed the calculations you have provided and choose to
            {selectedAction == bulkActionOptions[0].value ? ' submit them for review.' : ' approve them.'}
            {!checked && ' Note that you have chosen to ' + 
                (selectedAction == bulkActionOptions[0].value ? 'submit calculations for which you might not be the calc assignee or builder.' :
                'approve calculations for which you might not be the workbook reviewer.')
            }
            <br/><br/>
            Click on <b>Confirm</b> to proceed.
        </TPEBasicModal>
    </div>
}

function setupWebSocketEvents(services: ServiceCollection, setProgressUpdate: (string: any) => any, 
    setResult: (result: any) => any, setLoading: (loading: boolean) => any) {
    const listener = (x:any) => {
        const webSocketMessage = JSON.parse(x);

        //Listen for messages from bulk reversal API
        if (webSocketMessage?.messageType == CONSTANTS.WEB_SOCKET_API_ROUTES.PERFORM_BULK_ACTION) {
            if (webSocketMessage.status === CONSTANTS.PROCESSING_STATUS.FAILED) {
                services.messageService.showErrorBanner(webSocketMessage.message);
                setLoading(false);
            } else if (webSocketMessage.status === CONSTANTS.PROCESSING_STATUS.IN_PROGRESS) {
                setProgressUpdate(webSocketMessage.message);
            } else if (webSocketMessage.status === CONSTANTS.PROCESSING_STATUS.SUCCEEDED) {
                const jsonResult = JSON.parse(webSocketMessage.message);
                if (jsonResult.errors != null) {
                    jsonResult.errors = new Map(Object.entries(jsonResult.errors));
                    jsonResult.errors.forEach((v: string, k: string) => console.log(k, v));
                }
                setResult(jsonResult);
                setProgressUpdate(null as unknown as string);
                setLoading(false);
            }
        }
    };

    const errorHandler = (x:any) => {
        services.messageService.showErrorBanner(CONSTANTS.WEBSOCKET_ERROR_MESSAGE);
        setTimeout(() => {
            setResult(null);
            setProgressUpdate(null as unknown as string);
            setLoading(false);
        }, 500);
    }

    services.atpWebSocketApiService.addListener(listener);
    services.atpWebSocketApiService.addKeyListener(WebSocketApi.ConnectionEvent.ON_ERROR, errorHandler);
    return listener;
}