import React from "react";
import ServiceCollection from "src/services/ServiceCollection";
import {Header, Tabs} from "@amzn/awsui-components-react/polaris";
import DefaultMappingSectionView from "src/components/ic-account-mapping/DefaultMappingSectionView";
import ActivityLogSectionView from "src/components/ic-account-mapping/ActivityLogSectionView";
import {useHistory, useLocation} from "react-router-dom";
import CONSTANTS from "src/utils/constants";
import {MANAGE_EXCEPTIONS_TAB_URL_PREFIX} from "src/components/ic-account-mapping/constants";
import ManageExceptionsSectionView from "src/components/ic-account-mapping/ManageExceptionsSectionView";

export default function ICAccountMappingView(props: { services: ServiceCollection }) {
    const history = useHistory();
    const location = useLocation();

    const getActiveTabId = () => {
        if (location.pathname.includes('manageExceptions')) return 'manageExceptions';
        if (location.pathname.includes('activityLog')) return 'activityLog';
        return 'defaultMapping';
    };

    return (
        <>
            {
                // TODO:  Remove this when the page is ready
                CONSTANTS.ENVIRONMENT_VARIABLES.TPE_UI_STAGE === 'prod' ? <div>
                    This page is under development
                </div> : (
                    <>
                        {/*TODO: Replace SEP-24 with dynamic value of upcoming accounting period*/}
                        <Header variant="h3">Changes will be effective from MEC reporting cycle: SEP-24</Header>
                        <Tabs
                            activeTabId={getActiveTabId()}
                            onChange={({detail}) => {
                                history.push(detail.activeTabHref || "/icAccountMapping/defaultMapping");
                            }}
                            tabs={[
                                {
                                    label: "Default Mapping",
                                    id: "defaultMapping",
                                    content: <DefaultMappingSectionView services={props.services}/>,
                                    href: "/icAccountMapping/defaultMapping",
                                },
                                {
                                    label: "Manage Exceptions",
                                    id: "manageExceptions",
                                    content: <ManageExceptionsSectionView services={props.services}/>,
                                    href: MANAGE_EXCEPTIONS_TAB_URL_PREFIX
                                },
                                {
                                    label: "Activity Log",
                                    id: "activityLog",
                                    content: <ActivityLogSectionView/>,
                                    href: "/icAccountMapping/activityLog"
                                }
                            ]}
                        />
                    </>
                )
            }
        </>
    )
}