const DASHBOARD_CONSTANTS = Object.freeze({
    TOP_N: 5,
    OPEN_CALCULATION_REVIEW_FIELDS: {
        WORKBOOK_NAME: {
            ACCESSOR: 'workbookName',
            HEADER: 'CWB',
            DB_FIELD: ''
        },
        CALCULATION_NUMBER: {
            ACCESSOR: 'calculationNumber',
            HEADER: 'CLI',
            DB_FIELD: 'entity_id'
        },
        ATP_CALCULATION_STATUS: {
            ACCESSOR: 'atpCalculationStatus',
            HEADER: 'ATP CLI status',
            DB_FIELD: ''
        },
        APTTUS_CALCULATION_STATUS: {
            ACCESSOR: 'apttusCalculationStatus',
            HEADER: 'Apttus CLI status',
            DB_FIELD: ''
        },
        TASK_STATUS: {
            ACCESSOR: 'taskStatus',
            HEADER: 'Status',
            DB_FIELD: ''
        },
        DUE_DATE: {
            ACCESSOR: 'dueDateDisplay',
            HEADER: 'Due date',
            DB_FIELD: 'due_date'
        },
        OWNER: {
            ACCESSOR: 'owner',
            HEADER: 'Owner',
            DB_FIELD: 'recipient'
        },
        COMMENTS: {
            ACCESSOR: 'hasComments',
            HEADER: 'Comment',
            DB_FIELD: ''
        },
    },
    OPEN_APPROVAL_FIELDS: {
        ENTITY_ID: {
            ACCESSOR: 'entityId',
            HEADER: 'Action item',
            DB_FIELD: 'entityId'
        },
        ENTITY_TYPE: {
            ACCESSOR: 'entityType',
            HEADER: 'Category',
            DB_FIELD: 'entityType'
        },
        TASK_STATUS: {
            ACCESSOR: 'taskStatus',
            HEADER: 'Status'
        },
        DUE_DATE: {
            ACCESSOR: 'dueDateDisplay',
            HEADER: 'Due date',
            DB_FIELD: 'due_date'
        },
        REQUESTER: {
            ACCESSOR: 'requester',
            HEADER: 'Requester',
            DB_FIELD: 'requester'
        }
    },
    OPEN_REQUEST_FIELDS: {
        ENTITY_ID: {
            ACCESSOR: 'entityId',
            HEADER: 'Action item',
            DB_FIELD: 'entityId'
        },
        ENTITY_TYPE: {
            ACCESSOR: 'entityType',
            HEADER: 'Category',
            DB_FIELD: 'entityType'
        },
        TASK_STATUS: {
            ACCESSOR: 'taskStatus',
            HEADER: 'Status'
        },
        DUE_DATE: {
            ACCESSOR: 'dueDateDisplay',
            HEADER: 'Due date',
            DB_FIELD: 'due_date'
        },
        APPROVER: {
            ACCESSOR: 'approver',
            HEADER: 'Approver',
            DB_FIELD: 'recipient'
        }
    },
    ENTITY_TYPE_TO_DISPLAY_MAP: {
        'CALCULATION': 'Calculation'
    }
});

export default DASHBOARD_CONSTANTS;