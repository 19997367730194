export function camelCaseToSentenceCase(str: string): string {
    if (!str) return str;

    // Convert to sentence case
    return str
        // Add space before capital letters
        .replace(/([A-Z])/g, ' $1')
        // Convert to lowercase
        .toLowerCase()
        // Handle first character
        .replace(/^./, (char) => char.toUpperCase())
        // Handle ID casing
        .replace(/\bid\b/gi, 'ID')
        // Trim extra spaces
        .trim();
}

function formatTimezoneOffset(isoDate: string, offsetInMinutes?: number) {
    // Using default browser offset if not explicitly specified.
    offsetInMinutes = offsetInMinutes ?? 0 - new Date(isoDate).getTimezoneOffset();

    const sign = offsetInMinutes < 0 ? '-' : '+';
    const hoursOffset = Math.floor(Math.abs(offsetInMinutes) / 60)
        .toFixed(0)
        .padStart(2, '0');
    const minuteOffset = Math.abs(offsetInMinutes % 60)
        .toFixed(0)
        .padStart(2, '0');
    return `${sign}${hoursOffset}:${minuteOffset}`;
}

export function formatDateTime(isoDate: string): string {
    return isoDate ? isoDate + formatTimezoneOffset(isoDate) : '';
}