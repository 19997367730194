export interface GetAccountMappingResponse {
    mappingType: string,
    accountMappings: AccountMappingRecord[]
}

export enum AccountMappingStatus {
    MAPPED = "MAPPED",
    UNMAPPED = "UNMAPPED",
}

export enum AccountType {
    TOTAL_IC_REVENUE = "Total I/C Revenue",
    TOTAL_IC_COGS = "Total I/C COGS",
    TOTAL_IC_OPEX = "Total I/C OPEX",
    OTHER_INCOME_AND_DEDUCTION = "Other Income / Deductions"
}

export enum AccountMappingType {
    DEFAULT = "DEFAULT",
    CLI = "CLI",
    WORKBOOK = "WORKBOOK",
}

export interface AccountMappingRecord {
    accountId: string,
    accountName: string,
    parentAccountId: string,
    parentAccountName: string,
    mappingStatus: AccountMappingStatus,
    defaultMapping?: AccountType,
    exceptionMapping?: AccountType,
    exclusionSet?: string[] | null,
    workbookId?: string,
    updatedBy?: string,
    updatedAt?: string | Date,
}