import { Box, Container, Header, Link } from '@amzn/awsui-components-react';
import React from 'react';
import { OpenCalculationReviewsResult } from 'src/models/dashboard/OpenCalculationReview';
import OpenCalculationReviewsGrid from './OpenCalculationReviewsGrid';

export default function OpenCalculationReviewsContainer(props: {openCalculationReviewsResult: OpenCalculationReviewsResult, openCalculationReviewsLoading: boolean}) {
    const { openCalculationReviewsResult, openCalculationReviewsLoading } = props;

    return <Container
      header={
          <Header
          counter={openCalculationReviewsLoading ? '(...)' : `(${openCalculationReviewsResult ? openCalculationReviewsResult.totalSize : '0'})`}
          description="CLIs assigned to me that have status change from last MEC in ATP"
          >
          Open CLI review
          </Header>
      }
      footer={
        <Box textAlign="center">
          <Link href="#">View all CLI reviews</Link>
        </Box>
      }
    >
      <OpenCalculationReviewsGrid openCalculationReviewsResult={openCalculationReviewsResult} isLoading={openCalculationReviewsLoading} dashboardView={true}/>
    </Container>
}